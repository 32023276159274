import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AppTranslateService} from "../../../core/service/translate.service";
import {LocalStorageService} from "../../../core/service/local-storage-service";
@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss'],
})
export class Page404Component implements OnInit {
  constructor(private router: Router, private translateService: AppTranslateService,
              private localStorageService: LocalStorageService,
              private changeDetectorRef: ChangeDetectorRef) {
  }
  ngOnInit() {
    this.changementDeLangue();
  }

  goToDashboard=()=> this.router.navigate(['/dashboard/main']);

  changementDeLangue = (): void => {
    this.localStorageService.getStorageChanges().subscribe(() => {
      // Mettez à jour la langue avec la nouvelle valeur du local storage
      const newLanguage = this.localStorageService.getCurrentValue();
      this.translateService.setDefaultLanguage(newLanguage || 'en');
      // Forcer la détection des changements manuellement
      this.changeDetectorRef.detectChanges();
    });
  }
}
