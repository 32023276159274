import {Component, OnInit} from '@angular/core';
import {LoggerService} from "../../../core/service/logger.service";

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: [],
})
export class AuthLayoutComponent implements OnInit {
  constructor(private logger: LoggerService) {
  }

  ngOnInit(): void {
  }
}
