// create a file translate.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';
import { AppTranslateService} from "../service/translate.service";

@Pipe({
  name: 'dynamicTranslate',
  pure: false  // assurez-vous que le pipe n'est pas pur pour permettre des mises à jour dynamiques
})
export class DynamicTranslatePipe implements PipeTransform {
  constructor(private translateService: AppTranslateService) {}

  transform(key: string): string {
    return this.translateService.getTranslation(key);
  }
}
