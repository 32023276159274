import { Pipe, PipeTransform } from '@angular/core';
import { AppTranslateService} from "../service/translate.service";
import {WordingEn} from "../constants/wording.en";
import {WordingFr} from "../constants/wording.fr";
import {WordingEs} from "../constants/wording.es";

const languageMappings = {
  fr: WordingFr,
  es: WordingEs,
  en: WordingEn,
};
@Pipe({
  name: 'wording',
  pure: false, // Indique que le pipe n'est pas "purement immuable"
})
export class WordingPipe implements PipeTransform {
  constructor(private translateService: AppTranslateService) {
    this.translateService.initTranslateService();
  }


  transform<T>(key: string): string {
    const currentLanguage = this.translateService.getDefaultLanguage();

    const wording = (languageMappings as Record<string, any>)[currentLanguage] || WordingEn;


    const keys = key.split('.');
    let value: any = { ...wording } as T;

    for (const k of keys) {
      value = value[k];
      if (value === undefined) {
        // Retournez la clé elle-même si la traduction n'est pas trouvée
        return key;
      }
    }

    return typeof value === 'string' ? value : key;
  }
}
