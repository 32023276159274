import {NgModule} from '@angular/core';
import {AuthLayoutComponent} from './layout/app-layout/auth-layout/auth-layout.component';
import {MainLayoutComponent} from './layout/app-layout/main-layout/main-layout.component';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/guard/auth.guard';
import {Page500Component} from "./errors/page500/page500.component";

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: '/authentication/signin', pathMatch: 'full'},
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'calendar',
        loadChildren: () =>
          import('./calendar/calendar.module').then((m) => m.CalendarsModule),
      },
      {
        path: 'extra-pages',
        loadChildren: () =>
          import('./extra-pages/extra-pages.module').then(
            (m) => m.ExtraPagesModule
          ),
      },
      {
        path: 'gestion-eleves',
        loadChildren: () =>
          import('./gestions/gestion-eleves/gestion-eleves.module').then(
            (m) => m.GestionElevesModule
          ),
      },
      {
        path: 'gestion-notes',
        loadChildren: () =>
          import('./gestions/gestion-notes/gestion-notes.module').then(
            (m) => m.GestionNotesModule
          ),
      },
      {
        path: 'gestion-com',
        loadChildren: () =>
          import('./gestions/gestion-com/gestion-com.module').then(
            (m) => m.GestionComModule
          ),
      },
      {
        path: 'gestion-admin',
        loadChildren: () =>
          import('./gestions/gestion-admin/gestion-admin.module').then(
            (m) => m.GestionAdminModule
          ),
      },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  // Utilisation de 'loadChildren' pour charger le module d'erreur lorsque l'URL contient '/error'
  {path: 'error', loadChildren: () => import('./errors/error.module').then(m => m.ErrorModule)},
  {
    path: '**', component: Page500Component,
    loadChildren: () =>
      import('./errors/error.module').then(
        (m) => m.ErrorModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
