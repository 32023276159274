// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {Timezone} from "../app/core/enums/fuseaux-horaires";
import {DateFormatEnum} from "../app/core/enums/date-format-enum";
import {firebaseConfig} from "./firebase-config";

const connectToDevelop = 'https://api.dev.syl20n.com/';
export const environment = {
  production: false,
  apiUrl: connectToDevelop,
  firebase: firebaseConfig,
  defaultTimezone: Timezone.AFRICA_DOUALA,
  dateFormat: DateFormatEnum.YYYY_MM_DD,// Format par défaut yyyy-MM-dd
  enableLogs: true, // Activer les logs en dev
  features: {//permet d'activer certains element du formulaire
    showAdvancedOptions: true, // Afficher les options avancées en développement
    enableExperimentalFeatures: true, // Activer les fonctionnalités expérimentales
    disableFormFields: false, // activer certains champs en production
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
