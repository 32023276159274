import {NgModule} from '@angular/core';

import {CoreModule} from './core/core.module';

import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthLayoutComponent} from './layout/app-layout/auth-layout/auth-layout.component';
import {MainLayoutComponent} from './layout/app-layout/main-layout/main-layout.component';
import {HeaderComponent} from './layout/header/header.component';
import {PageLoaderComponent} from './layout/page-loader/page-loader.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {RightSidebarComponent} from './layout/right-sidebar/right-sidebar.component';
import {DatePipe, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {fakeBackendProvider} from './core/interceptor/fake-backend';
import {ErrorInterceptor} from './core/interceptor/error.interceptor';
import {JwtInterceptor} from './core/interceptor/jwt.interceptor';
import {ClickOutsideModule} from 'ng-click-outside';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {SharedModule} from "./core/shared/shared.module";
import {PipeModule} from "./core/pipes/pipe.module";
import {DateAdapter, MAT_DATE_FORMATS} from "@angular/material/core";
import {CustomDateAdapter} from "./core/types/date-format";
import {environment} from "../environments/environment";
import {AuthErrorInterceptor} from "./core/interceptor/AuthErrorInterceptor";

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    RightSidebarComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ClickOutsideModule,
    NgScrollbarModule,
    LoadingBarRouterModule,
    CoreModule,
    SharedModule,
    PipeModule,
  ],
  providers: [
    DatePipe,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthErrorInterceptor, multi: true},
    fakeBackendProvider,
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: {display: {dateInput: environment.dateFormat}}} // Utilise l'Enum
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
}
