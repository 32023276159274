export const UrlConfig = {
  firebase: {
    updateDeviceStatus: 'accounts/update-device-info',
  },
  user: {
    login: 'auth/signin/teacher',
    logout: 'auth/logout',
    schoolYears: 'schoolyears',
    forgotpassword: 'auth/forgotpassword/generatecode',
    codevalidation: 'auth/forgotpassword/validatecode',
  },
  student: {
    list: 'students',
    criteria: 'students/criteria',
    add: 'students/add',
    update: 'students/update',
    delete: {
      one: 'students/delete',
      many: 'students/deletemany',
    },
    desactivateOrActivate: {
      one: 'students/desactiveoractiveone',
      many: 'students/desactiveoractivemany',
    },
  },
  classe: {
    //ok
    list: 'classes',
    clone: 'classes/clone',
    add: 'classes/add',
    ordonner: 'classes/ordonner',
    update: 'classes/update',
    delete: 'classes/delete',
    listby: {
      search: 'classes/search',
    },
  },
  sequence: {
    list: 'sequences',
    add: 'sequences/add',
    update: 'sequences/update',
    delete: 'sequences/delete',
    listby: {
      search: 'sequences/search',
    },
  },
  section: 'sections',
  //trimestre
  quarter: {
    list: 'quarters',
    add: 'quarters/add',
    update: 'quarters/update',
    delete: 'quarters/delete',
    listby: {
      search: 'quarters/search',
    },
  },
  //annees scolaire
  schoolyear: {
    list: 'schoolyears',
    add: 'schoolyears/add',
    update: 'schoolyears/update',
    delete: 'schoolyears/delete',
  },
  //profil
  profil: {
    list: 'profils',
    add: 'profils/add',
    update: 'profils/update',
    delete: 'profils/delete',
  },
  //level
  level: {
    list: 'levels',
    initlevels: 'levels/initialiser',
    add: 'levels/add',
    update: 'levels/update',
    delete: 'levels/delete',
  },
  //Evaluation
  evaluation: {
    list: 'assessmenttypes',
    add: 'assessmenttypes/add',
    update: 'assessmenttypes/update',
    delete: 'assessmenttypes/delete',
    listby: {
      search: 'assessmenttypes/search',
    },
  },
  //Groupe
  group: {
    list: 'groups',
    add: 'groups/add',
    update: 'groups/update',
    delete: 'groups/delete',
    listby: {
      search: 'groups/search',
    },
  },
  //Course
  course: {
    list: 'courses',
    add: 'courses/add',
    update: 'courses/update',
    delete: 'courses/delete',
    listby: {
      search: 'courses/search',
    },
  },
  //Etablissement
  school: {
    list: 'schools',
    add: 'schools/add',
    update: 'schools/update',
    delete: 'schools/delete',
    initcourses: 'schools/initialiserlescours',
    listby: {
      search: 'schools/search',
    },
  },
  //Verification
  verification: {
    list: 'verifyaccounts',
    add: 'verifyaccounts/add',
    update: 'verifyaccounts/update',
    delete: 'verifyaccounts/delete',
    phoneNumber: 'verifyaccounts/phonenumber',
    listby: {
      search: 'verifyaccounts/search',
    },
  },
  //Compte utilisateur
  account: {
    list: 'accounts',
    add: 'accounts/add',
    update: 'accounts/update',
    delete: 'accounts/delete',
    listby: {
      search: 'accounts/search',
    },
  },
  //Enseignant
  teacher: {
    list: 'teachers',
    renewed: 'teachers/renewed/school',
    renew: 'teachers/renew',
    criteria: 'teachers/criteria',
    staff: 'teachers/staffs',
    add: 'teachers/add',
    update: 'teachers/update',
    assignclass: 'teachers/assignclass',
    teacheclass: 'teachers/teaches',
    delete: 'teachers/delete',
    listby: {
      search: 'teachers/search',
    },
  },
  //Parent
  tutor: {
    list: 'tutors',
    delete: 'tutors/delete',
  },
  //Option
  option: {
    list: 'options',
    add: 'options/add',
    update: 'options/update',
    delete: 'options/delete',
    level: 'options/level',
    section: 'options/section',
  },
  //Option
  inscription: {
    list: 'inscriptions',
    criteria: 'inscriptions/criteria',
    checkcriteria: 'inscriptions/criteria/check',
    add: 'inscriptions/add',
    update: 'inscriptions/update',
    delete: 'inscriptions/delete',
  },
  delay: {
    list: 'delays',
    criteria: 'delays/criteria',
    userCriteria: 'delays/criteria/userid',
    add: 'delays/add',
    delete: 'delays/delete',
  },
  note: {
    list: 'notes',
    criteria: 'notes/criteria',
    userCriteria: 'notes/criteria/userid',
    notesSequentielle: 'notes/criteria/notessequentielle',
    notesTrimestrielle: 'notes/criteria/notestrimestrielle',
    add: 'notes/add',
    delete: 'notes/delete',
  },
  program: {
    list: 'programs',
    criteria: 'programs/criteria',
    find: 'programs/find/criteria',
    addupdate: 'programs/addupdate',
    update: 'programs/update',
    delete: 'programs/delete',
    listby: {
      search: 'programs/search',
    },
  },
  plagehoraire: {
    list: 'timeslots',
    listbyschool: 'timeslots/school',
    add: 'timeslots/add',
    update: 'timeslots/update',
    delete: 'timeslots/delete',
    listby: {
      search: 'timeslots/search',
    },
  },
  planninghoraire: {
    timeslots: 'schools/time-slots',
    list: 'time-schedules',
    listbyschool: 'timeschedules/school',
    add: 'timeschedules/add',
    timeschedule: {
      add: 'time-schedules/execute-add',
      update: 'time-schedules/execute-update',
      delete: 'time-schedules/execute-delete',
      list: 'time-schedules',
    },

    update: 'timeschedules/update',
    delete: 'timeschedules/delete',
    listby: {
      search: 'timeschedules/search',
    },
  },
  statistics: {
    total: {
      students: 'inscriptions/total',
      parents: 'tutors/count/school',
    },
  },
  //News and Event
  newsAndEvent: {
    list: 'newsandevents',
    sendings: 'newsandevents/sendings',
    add: 'newsandevents/add',
    update: 'newsandevents/update',
    delete: 'newsandevents/delete',
  },
  download: {
    url: (uid: number) => `notes/download/report/student_report_${uid}.pdf`,
  },
};
