import {EventEmitter, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Civilite} from '../enums/civilite';
import {CiviliteTranslations} from '../translations/civilite-translations';
import {LocalStorageService} from "./local-storage-service";
import {Paginator} from "../enums/paginator";
import {PaginatorTranslations} from "../translations/paginator-translations";
import {PaymentStatus} from "../enums/payment-status";
import {PaymentStatusTranslations} from "../translations/payment-status-translations";
import {LoggerService} from "./logger.service";

@Injectable({
  providedIn: 'root'
})
export class AppTranslateService {
  public onDefaultLanguageChange: EventEmitter<string> = new EventEmitter<string>();
  private defaultLanguage: string = 'en';
  private languageSubject = new BehaviorSubject<string>(this.defaultLanguage);
  public language$ = this.languageSubject.asObservable();

  constructor(private translateService: TranslateService, private localStorageService: LocalStorageService, private logger: LoggerService) {
    this.initTranslateService();
  }

  initTranslateService(): void {
    this.translateService.setDefaultLang('en');
    const storedLanguage = this.localStorageService.getCurrentValue();
    const browserLang = this.translateService.getBrowserLang();
    const fallbackLang = 'en';
    if (storedLanguage) {
      this.translateService.use(storedLanguage.match(/en|fr/) ? storedLanguage : fallbackLang);
    } else {
      this.translateService.use(storedLanguage || fallbackLang);
    }
    this.translateService.onLangChange.subscribe((event) => {
      // this.logger.log(`Langue changée : ${event.lang}`);
      this.languageSubject.next(event.lang); // Émettre la nouvelle langue aux abonnés
    });
  }

  /**
   * traduit dans le ts et peut appliquer la pipe sur le front
   * @param key
   */
  getTranslation(key: string): string {
    return this.translateService.instant(key);
  }

  getTranslation2(key: string, language?: string): Observable<string> {
    return this.translateService.get(key, {lang: language});
  }

  /**
   * Traduit uniquement dans le ts
   * @param key
   * @param language
   */
  getTranslateValue(key: string, language: string): string {
    let translation: string;

    // Store the current language
    const currentLanguage = this.translateService.currentLang;

    // Change the language temporarily for this translation
    this.translateService.use('en');

    // Manually set the language before calling instant
    this.translateService.currentLang = 'en';

    // Get the translation
    translation = this.translateService.instant(key);

    // Revert back to the original language
    this.translateService.use(currentLanguage);

    // Manually set the language back to the original
    this.translateService.currentLang = currentLanguage;

    this.logger.log(`Translation for key ${key} in language ${language}: ${translation}`);
    return translation;
  }

  setLanguage(language: string): void {
    this.translateService.use(language);
  }

  setDefaultLanguage(language: string): void {
    this.defaultLanguage = language;
    localStorage.setItem('appLanguage', language);
    this.translateService.setDefaultLang(language);
    this.onDefaultLanguageChange.emit(language);
  }

  getDefaultLanguage = () => this.defaultLanguage;

  translateCivilite(civilite: Civilite, language: string): string {
    const translation = CiviliteTranslations[civilite] as { [key: string]: string };
    return translation ? translation[language] : '';
  }

  translatePaginator(paginator: Paginator): string {
    const translation = PaginatorTranslations[paginator] as { [key: string]: string };
    return translation ? translation[this.getDefaultLanguage()] : '';
  }

  translatePaymentStatus(paymentStatus: PaymentStatus): string {
    const translation = PaymentStatusTranslations[paymentStatus] as { [key: string]: string };
    return translation ? translation[this.getDefaultLanguage()] : '';
  }

  translateFormButtonName(data: any): { addUpdatebtn: string, dialogTitle: string } {

    type TranslationKey =
      'add'
      | 'edit'
      | 'delete'
      | 'search_plus'
      | 'restore'
      | 'register_one'
      | 'register_many'
      | 'initialise'
      | 'download'
      | 'clone' | 'assign' | 'renew';

    type Translations = Record<TranslationKey, {
      button: string;
    }>;

    const translations: Translations = {
      'add': {
        button: 'FORM.SAVE_BTN'
      },
      'edit': {
        button: 'FORM.EDIT_BTN'
      },
      'delete': {
        button: 'FORM.DELETE_BTN'
      },
      'search_plus': {
        button: 'FORM.SEARCH_PLUS_BTN'
      },
      'restore': {
        button: 'FORM.RESTORE_BTN'
      },
      'register_one': {
        button: 'FORM.REGISTER_BTN'
      },
      'register_many': {
        button: 'FORM.REGISTER_ALL_BTN'
      },
      'initialise': {
        button: 'FORM.INITIALIZE_BTN'
      },
      'download': {
        button: 'FORM.DOWNLOAD_BTN'
      },
      'clone': {
        button: 'FORM.CLONE_BTN'
      },
      'assign': {
        button: 'FORM.ASSIGN_BTN'
      },
      'renew': {
        button: 'FORM.RENEW_BTN'
      }
    };

    const translationKeys: { button: string } = translations[data.action as TranslationKey] || translations['add'];

    const addButtonKey = translationKeys.button;

    const addUpdatebtn = this.getTranslation(addButtonKey);
    const dialogTitle = data.cardTitle;

    return {addUpdatebtn, dialogTitle};
  }

}
