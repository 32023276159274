import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private logsEnabled = environment.enableLogs;

  setLogging(enable: boolean): void {
    this.logsEnabled = enable;
  }

  log(...args: any[]): void {
    if (this.logsEnabled) {
      console.log(...args);
    }
  }

  warn(...args: any[]): void {
    if (this.logsEnabled) {
      console.warn(...args);
    }
  }

  error(...args: any[]): void {
    if (this.logsEnabled) {
      console.error(...args);
    }
  }
}
