import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {debounceTime, map, switchMap, tap} from 'rxjs/operators';
import {User} from '../models/user';
import {SchoolYear} from "../models/school-year";
import {SearchParamsCriteria} from "../models/search-params-criteria";
import {LoggerService} from "./logger.service";


@Injectable({
  providedIn: 'root'
})
export class CrudService implements OnInit {
  apiUrlRoot: any;
  entities: any;
  entity: any;
  token: any;
  httpOptions: any;
  baseEnv = environment;
  public currentUser$!: Observable<User>;
  private currentUserSubject!: BehaviorSubject<User | null>;
  // Créer une valeur par défaut représentant un utilisateur invité ou null si approprié
  private readonly defaultUser: User | null = null;

  private actionSubject = new BehaviorSubject<string>('');
  readonly action$ = this.actionSubject.asObservable();

  constructor(private http: HttpClient, private logger: LoggerService) {
  }

  ngOnInit(): void {
  }

  /**
   * @returns recuperer la valeur du user connecter
   */
  currentUserValue(): User | null {
    return this.retrieveFromLocalStorage<User>('currentUser');
  }

  currentSchoolYearValue(): SchoolYear | null {
    return this.retrieveFromLocalStorage<SchoolYear>('currentSchoolYear');
  }

  checkToken = () => {
    const currentUser = this.currentUserValue();
    this.token = currentUser ? currentUser.accessToken : null;

    this.apiUrlRoot = this.token
      ? `${this.baseEnv.apiUrl}app/`
      : `${this.baseEnv.apiUrl}api/`;

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        ...(this.token && {Authorization: `Bearer ${this.token}`}),
      }),
    };
  };


  getBaseEnv = (): any => this.baseEnv;

  getEntities(entity: string): Observable<any> {
    this.checkToken();
    return this.http.get(this.apiUrlRoot + entity, this.httpOptions).pipe(
      map((response: any) => {
        this.entities = response;
        return this.entities;
      }),
    );
  }

  /**
   * Récupère les donnees en fonction des critères spécifiés.
   * @param entity Le nom de l'entité pour laquelle récupérer les donnees.
   * @param params Les critères de recherche pour filtrer les résultats.
   * @returns Un observable contenant les données de l'entite récupérées.
   */
  getBySearchParams(entity: string, params: SearchParamsCriteria): Observable<any> {
    this.checkToken();
    let httpParams = new HttpParams();

    // Ajout de sort aux paramètres de requête s'il n'est pas vide
    if (params.sort && params.sort.length > 0) {
      params.sort.forEach(sortItem => {
        httpParams = httpParams.append('sort', sortItem);
      });
    }

    // Ajout de range aux paramètres de requête s'il n'est pas vide et que les valeurs sont supérieures à zéro
    if (params.range && params.range.length === 2 && params.range.every(value => value > 0)) {
      httpParams = httpParams.append('range', params.range[0].toString());
      httpParams = httpParams.append('range', params.range[1].toString());
    }

    // Ajout de searchValue aux paramètres de requête s'il n'est pas vide
    if (params.searchValue) {
      httpParams = httpParams.append('searchValue', params.searchValue);
    }

    // Ajout de userId aux paramètres de requête s'il n'est pas vide et que la valeur est supérieure à zéro
    if (params.userId && params.userId > 0) {
      httpParams = httpParams.append('userId', params.userId.toString());
    }


    // if (params.filter && Object.keys(params.filter).length > 0) {
    //   httpParams = httpParams.append('filter', JSON.stringify(params.filter));
    // }

    // Filtrage des propriétés du filtre dont les valeurs sont égales à 0
    if (params.filter) {
      const filteredFilter = Object.fromEntries(
        Object.entries(params.filter).filter(([_, value]) => value !== 0)
      );
      // Ajout du filtre aux paramètres de requête
      if (Object.keys(filteredFilter).length > 0) {
        httpParams = httpParams.append('filter', JSON.stringify(filteredFilter));
      }
    }

    return this.http.get(this.apiUrlRoot + entity + '/criteria', {
      headers: this.httpOptions.headers,
      params: httpParams
    });
  }

  findEntities(entity: string, payloads: any): Observable<any> {
    this.checkToken();
    return this.http.get(this.apiUrlRoot + entity + payloads, this.httpOptions).pipe(
      map((response: any) => {
        this.entities = response;
        return this.entities;
      }),
    );
  }

  findEntities5(entity: string, payloads: any): Observable<any> {
    this.checkToken();
    const url = this.apiUrlRoot + entity;
    const params = new HttpParams({fromObject: payloads});
    return this.http.get(url, {params});
  }

  getElement(entity: string): Observable<any> {
    this.checkToken();
    return this.http.get(this.apiUrlRoot + entity, this.httpOptions).pipe(
      map((response: any) => {
        this.entity = response;
        return this.entity;
      }),
    );
  }

  getEntity(entity: string, payloads: any): Observable<any> {
    this.checkToken();
    return this.http.post(this.apiUrlRoot + entity, payloads, this.httpOptions).pipe(
      map((response: any) => {
        this.entity = response;
        return this.entity;
      }),
    );
  }

  /**
   * Envoie une requête POST à l'URL spécifiée avec des paramètres d'URL optionnels.
   * Cette fonction remplace les variables de chemin (@PathVariable) et ajoute les paramètres de requête (@RequestParam) à l'URL.
   *
   * @param url L'URL de la requête POST.
   * @param pathVariables Les variables de chemin à remplacer dans l'URL (optionnel).
   * @param queryParams Les paramètres de requête à ajouter à l'URL (optionnel).
   * @returns Un Observable contenant la réponse de la requête POST.
   * @private
   */
  public requestWithUrlParams(method: string, url: string, pathVariables?: any, queryParams?: any): Observable<any> {
    this.checkToken();
    // Construction de l'URL avec les path variables et les query parameters
    let fullUrl = this.apiUrlRoot + url;
    if (pathVariables) {
      // Remplacement des path variables dans l'URL
      Object.keys(pathVariables).forEach(key => {
        fullUrl = fullUrl.replace(`{${key}}`, encodeURIComponent(pathVariables[key]));
      });
    }
    if (queryParams) {
      // Construction des query parameters
      const params = new URLSearchParams();
      Object.keys(queryParams).forEach(key => {
        params.set(key, encodeURIComponent(queryParams[key]));
      });
      // Ajout des query parameters à l'URL
      fullUrl += `?${params.toString()}`;
    }

    // Envoi de la requête avec la méthode spécifiée à l'URL construite
    if (method === 'GET') {
      return this.http.get(fullUrl, this.httpOptions);
    } else if (method === 'POST') {
      return this.http.post(fullUrl, {}, this.httpOptions);
    } else if (method === 'PUT') {
      return this.http.put(fullUrl, {}, this.httpOptions);
    } else {
      throw new Error('Invalid HTTP method');
    }
  }

  addEntity(entity: string, payloads: any): Observable<any> {
    return this.postRequest(entity, payloads);
  }

  /**
   * Envoie une requête POST à l'URL spécifiée avec des paramètres d'URL optionnels.
   * @param entity url L'URL de la requête POST.
   * @param payloads la/les variables @PathVariable et/ou @RequestParam
   */
  getWithUrlParams(entity: string, payloads: any): Observable<any> {
    return this.requestWithUrlParams('GET', entity, payloads);
  }

  /**
   * Envoie une requête POST à l'URL spécifiée avec des paramètres d'URL optionnels.
   * @param entity url L'URL de la requête POST.
   * @param payloads la/les variables @PathVariable et/ou @RequestParam
   */
  postWithUrlParams(entity: string, payloads: any): Observable<any> {
    return this.requestWithUrlParams('POST', entity, payloads);
  }

  /**
   * Envoie une requête PUT à l'URL spécifiée avec des paramètres d'URL optionnels.
   * @param entity url L'URL de la requête PUT.
   * @param payloads la/les variables @PathVariable et/ou @RequestParam
   */
  puttWithUrlParams(entity: string, payloads: any): Observable<any> {
    return this.requestWithUrlParams('PUT', entity, payloads);
  }

  fetchEntityByCriteria(entity: string, payloads: any): Observable<any> {
    return this.postRequest(entity, payloads);
  }

  updateEntity(entity: string, payloads: any): Observable<any> {
    this.checkToken();
    return this.http.put(this.apiUrlRoot + entity, payloads, this.httpOptions).pipe(
      map((response: any) => {
        this.entity = response;
        return this.entity;
      }),
    );
  }

  deleteEntity(entity: string, id: number): Observable<any> {
    this.checkToken();
    return this.http.delete(this.apiUrlRoot + entity + '/' + id, this.httpOptions).pipe(
      map((response: any) => {
        this.entity = response;
        return this.entity;
      }),
    );
  }

  deleteManyEntity(entity: string, payloads: any): Observable<any> {
    this.checkToken();
    return this.http.post(this.apiUrlRoot + entity, payloads, this.httpOptions).pipe(
      map((response: any) => {
        this.entity = response;
        return this.entity;
      }),
    );
  }

  public setAction(input: string): void {
    this.checkToken();
    this.actionSubject.next(input);
  }

  readonly autocomplete$ = (entity: string): Observable<any[]> =>
    this.action$.pipe(
      // Taps the emitted value from action stream
      tap((data: string) => this.logger.log('input:', data)),
      // Wait for 250 ms to allow the user to finish typing
      debounceTime(250),
      // switchMap fires REST based on above input
      switchMap(input =>
        (!!input && input.trim().length > 1
            ? this.http.get<any[]>(`${(this.apiUrlRoot + entity, this.httpOptions)}/${input}`)
            : of([])
        ).pipe(
          // Additional sorting on switchMap output
          map((classes: any[]) =>
            classes.sort((classe1, classe2) => classe1.libelle.localeCompare(classe2.libelle)),
          ),
          // Taps the final emitted value from inner observable
          tap((data: any[]) => this.logger.log('output:', data)),
        ),
      ),
    );

  downloadFile(url: string): Observable<any> {
    this.checkToken();
    return this.http.get(this.apiUrlRoot + url, {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
    });
  }

  private retrieveFromLocalStorage<T>(key: string): T | null {
    const storedValue = localStorage.getItem(key);
    const parsedValue = storedValue ? JSON.parse(storedValue) : null;
    const subject = new BehaviorSubject<T | null>(parsedValue);
    return subject.value;
  }

  private postRequest(entity: string, payloads: any): Observable<any> {
    this.checkToken();
    return this.http.post(this.apiUrlRoot + entity, payloads, this.httpOptions).pipe(
      map((response: any) => {
        this.entity = response;
        return this.entity;
      }),
    );
  }
}

//Unit test of bolean value in an array
