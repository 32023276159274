export enum FormRequiredMessage {
    code= 'Veuillez saisir le code envoyer',
    saisirnouveaupassword= 'Veuillez Saisissez le nouveau mot de passe',
    confirmPassword= 'Veuillez Confirmer le nouveau mot de passe',
    passwordsNotmatch= 'Les mots de passe ne correspondent pas.',
    passwordsMatch= 'Les mots de passe correspondent.',
    fieldRequire= 'Le champ est requis',
    confirmPasswordError= 'Erreur de confirmation',
    saisiradresselectronique= 'Veuillez saisir une adresse électronique valide',
    usernameOrPhoneNumber= 'Username or Phone Number is required',
    password= 'Password is required',
  }


  export enum FormLabels  {

    //valider component
    letUsHelpYou= 'Laissez-nous vérifier les détails de votre compte',
    resetPassword= 'Vérification OTP',
    resetMyPassword= 'Vérifier',
    code= 'code envoyer',
    saisirnouveaupassword= 'nouveau mot de passe',
    confirmPassword= 'Confirmer le nouveau mot de passe',
    backToLogin= 'Connexion?',

    //password forget component
    laisseznousvousaidez= 'Laissez-nous vous aider',
    reinitialiserlepwd= 'Réinitialiser le mot de passe',
    reinitialisermonpwd='Réinitialiser mon mot de passe',
    saisiradresselectronique= 'Saisissez votre adresse électronique enregistrée.',
    usernameOrPhoneNumber= 'Nom d\'utilisateur ou numéro de téléphone',

  //Signin component
    password= 'Mot de passe',
    title= 'Bienvenue sur transparency',
    needAccount= 'Besoin d\'un compte?',
    forgotPassword= 'Mot de passe oublié?',
    signIn= 'Se connecter',
    signUp= 'S\'inscrire',
    remember= 'Se souvenir de moi',
    login= 'Connexion',
    or= 'ou',

  //Headers
  settings='Paramètres',
  account='Compte',
  logout='Déconnexion',
  inbox='Boîte de réception',
  readAllNotifications = 'Lire toutes les notifications',
  markAllAsRead='Marquer tous comme lus',
  notitications='Notitications',

  }
  export enum FormFields {
    enterRegisteredUsernameOrPhoneNumber= 'enterRegisteredUsernameOrPhoneNumber',
    code= 'code',
    password= 'password',
    confirmPassword= 'confirmPassword',
    usernameOrPhoneNumber= 'usernameOrPhoneNumber',

    saisiradresselectronique= 'saisiradresselectronique',
  }
