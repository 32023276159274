import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private storageKey = 'appLanguage'; // La clé en local storage que vous souhaitez surveiller
  private storageSubject = new BehaviorSubject<string | null>(localStorage.getItem(this.storageKey));

  private currentSchoolKey = 'currentSchool';
  private currentSchoolSubject = new BehaviorSubject<string | null>(localStorage.getItem(this.currentSchoolKey));

  private currentSchoolYearKey = 'currentSchoolYear';
  private currentSchoolYearSubject = new BehaviorSubject<string | null>(localStorage.getItem(this.currentSchoolYearKey));

  private currentSectionKey = 'currentSection';
  private currentSectionSubject = new BehaviorSubject<string | null>(localStorage.getItem(this.currentSectionKey));

  private currentClassKey = 'currentClass';
  private currentClassSubject = new BehaviorSubject<string | null>(localStorage.getItem(this.currentClassKey));

  private currentSequenceKey = 'currentSequence';
  private currentSequenceSubject = new BehaviorSubject<string | null>(localStorage.getItem(this.currentSequenceKey));

  private currentProgramKey = 'currentProgram';
  private currentProgramSubject = new BehaviorSubject<string | null>(localStorage.getItem(this.currentProgramKey));

  private canAddOrUpdateNoteKey = 'canAddOrUpdateNote';
  private canAddOrUpdateNoteSubject = new BehaviorSubject<string | null>(localStorage.getItem(this.canAddOrUpdateNoteKey));

  constructor() {
    // Vérification périodique de la valeur en local storage
    this.setupStorageInterval(this.storageKey, this.storageSubject);
    this.setupStorageInterval(this.currentSchoolKey, this.currentSchoolSubject);
    this.setupStorageInterval(this.currentSchoolYearKey, this.currentSchoolYearSubject);
    this.setupStorageInterval(this.currentSectionKey, this.currentSectionSubject);
    this.setupStorageInterval(this.currentClassKey, this.currentClassSubject);
    this.setupStorageInterval(this.currentSequenceKey, this.currentSequenceSubject);
    this.setupStorageInterval(this.currentProgramKey, this.currentProgramSubject);
    this.setupStorageInterval(this.canAddOrUpdateNoteKey, this.canAddOrUpdateNoteSubject);
  }

  private setupStorageInterval(key: string, subject: BehaviorSubject<string | null>): void {
    setInterval(() => {
      const newValue = localStorage.getItem(key);
      if (newValue !== subject.value) {
        subject.next(newValue);
      }
    }, 1000); // Changer la fréquence selon vos besoins
  }

  getStorageChanges(): Observable<string | null> {
    return this.storageSubject.asObservable();
  }

  getCurrentSchoolChanges(): Observable<any | null> {
    return this.currentSchoolSubject.asObservable();
  }

  getCurrentSchoolYearChanges(): Observable<any | null> {
    return this.currentSchoolYearSubject.asObservable();
  }

  getCurrentSectionChanges(): Observable<any | null> {
    return this.currentSectionSubject.asObservable();
  }

  getCurrentClassroomChanges(): Observable<any | null> {
    return this.currentClassSubject.asObservable();
  }

  getCurrentSequenceChanges(): Observable<any | null> {
    return this.currentSequenceSubject.asObservable();
  }

  getCurrentProgramChanges(): Observable<any | null> {
    return this.currentProgramSubject.asObservable();
  }

  getCanAddOrUpdateNoteChanges(): Observable<any | null> {
    return this.canAddOrUpdateNoteSubject.asObservable();
  }

  getCurrentValue(): string | null {
    return localStorage.getItem(this.storageKey);
  }

  getCurrentSchoolValue(): any | null {
    return localStorage.getItem(this.currentSchoolKey);
  }

  getCurrentSchoolYearValue(): any | null {
    return localStorage.getItem(this.currentSchoolKey);
  }

  isLocalStorageNotEmpty(key: string): boolean {
    const item = localStorage.getItem(key);
    if (item) {
      try {
        const parsedItem = JSON.parse(item);
        return !!Object.keys(parsedItem).length; // Si l'objet a des propriétés, retourne true, sinon false
      } catch (error) {
        console.error('Erreur lors de l\'analyse de l\'élément du localStorage :', error);
        return false; // En cas d'erreur de parsing, retourne false
      }
    }
    return false; // Si l'élément n'existe pas dans le localStorage, retourne false
  }

  // Méthodes pour effectuer des modifications
  setCurrentValue(value: string): void {
    localStorage.setItem(this.storageKey, value);
    this.storageSubject.next(value);
  }

  setCurrentLanguageValue(value: string): void {
    localStorage.setItem(this.storageKey, value);
    this.storageSubject.next(value);
  }

  setCurrentSchoolValue(value: any): void {
    localStorage.setItem(this.currentSchoolKey, JSON.stringify(value));
    this.currentSchoolSubject.next(JSON.stringify(value));
  }

  setCurrentSchoolYearValue(value: any): void {
    localStorage.setItem(this.currentSchoolYearKey, JSON.stringify(value));
    this.currentSchoolYearSubject.next(JSON.stringify(value));
  }

  setCurrentSectionValue(value: any): void {
    localStorage.setItem(this.currentSectionKey, JSON.stringify(value));
    this.currentSectionSubject.next(JSON.stringify(value));
  }

  setCurrentClassroomValue(value: any): void {
    localStorage.setItem(this.currentClassKey, JSON.stringify(value));
    this.currentClassSubject.next(JSON.stringify(value));
  }

  setCurrentSequenceValue(value: any): void {
    localStorage.setItem(this.currentSequenceKey, JSON.stringify(value));
    this.currentSequenceSubject.next(JSON.stringify(value));
  }

  setCurrentProgramValue(value: any): void {
    localStorage.setItem(this.currentProgramKey, JSON.stringify(value));
    this.currentProgramSubject.next(JSON.stringify(value));
  }

  setCanAddOrUpdateNoteValue(value: any): void {
    localStorage.setItem(this.canAddOrUpdateNoteKey, JSON.stringify(value));
    this.canAddOrUpdateNoteSubject.next(JSON.stringify(value));
  }
}
