// /src/app/core/translations/enum-translations.type.ts
import {Paginator} from "../enums/paginator";

export const PaginatorTranslations = {
  [Paginator.ITEMS_PER_PAGE]: {
    en: 'Items per page',
    fr: 'Éléments par page',
  },
  [Paginator.PREVIOUS_PAGE]: {
    fr: 'Page précédente',
    en: 'Previous page',
  },
  [Paginator.FIRST_PAGE]: {
    fr: 'Première page',
    en: 'First page',
  },
  [Paginator.NEXT_PAGE]: {
    fr: 'Page suivante',
    en: 'Next page',
  },
  [Paginator.LAST_PAGE]: {
    fr: 'Dernière page',
    en: 'Last page',
  },
  [Paginator.OF]: {
    fr: 'sur',
    en: 'of',
  },
  // Ajoutez d'autres traductions pour Civilite au besoin
} as const;
