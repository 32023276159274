// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyD7wdS0XU3oujAq1Y8Heur5v-Cx9qfgi30",
  authDomain: "snofy-api.firebaseapp.com",
  projectId: "snofy-api",
  storageBucket: "snofy-api.firebasestorage.app",
  messagingSenderId: "547353889043",
  appId: "1:547353889043:web:9eacfecd60a4f47ee832e6",
  measurementId: "G-0KL1C5NR9P",
  vapidKey: "BH2T8pZYoi2lR0zV39VWe4DWeCr09aZLXIpdzVTD__tf-PuHGh0zqAlEDFe7i-HYuvcmjW3rYwtDfUXkq35VqWU",
};
