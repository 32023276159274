// /src/app/core/constants/wording.fr.ts
export const WordingFr = {
  FILE_ARIANE: {
    STUDENT: {
      TITLE: 'Elève',
      HOME: 'Accueil',
      ITEM: 'Gestion-élèves',
      ITEM_ACTIVE: 'élèves',
    },
    INSCRIPTION: {
      TITLE: 'Inscription',
      HOME: 'Accueil',
      ITEM: 'Gestion-élèves',
      ITEM_ACTIVE: 'inscription',
    },
    SETTING: {
      TITLE: 'Paramètres',
      HOME: 'Accueil',
      ITEM: 'Administration',
      ITEM_ACTIVE: 'console',
    },
    CONFIG: {
      TITLE: 'Paramètres',
      HOME: 'Accueil',
      ITEM: 'Administration',
      ITEM_ACTIVE: 'config',
    },
  },
  LOGIN: {
    WELCOME_MESSAGE: 'Bienvenue dans notre application !',
    USERNAME: 'Nom d\'utilisateur',
    PASSWORD: 'Mot de passe',
    SIGN_IN: 'Se connecter',
    FORGOT_PASSWORD: 'Mot de passe oublié ?',
  },
  SIGNUP: {
    CREATE_ACCOUNT: 'Créer un compte',
    EMAIL: 'Email',
    CONFIRM_PASSWORD: 'Confirmer le mot de passe',
    TERMS_CONDITIONS: 'J\'accepte les conditions d\'utilisation',
    SIGN_UP: 'S\'inscrire',
  },
  FORGOT_PASSWORD: {
    RESET_PASSWORD: 'Réinitialiser le mot de passe',
    ENTER_EMAIL: 'Entrez votre adresse email',
    SEND_RESET_LINK: 'Envoyer le lien de réinitialisation',
  },
  FORM: {
    LABEL: {
      REG_NUMBER: 'Matricule: ',
      SEARCH_VALUE: 'Rechercher',
      PLACEHOLDER: {
        SEARCH_VALUE: 'Rechercher',
      },
      REQUIRED: {
        SEARCH_VALUE: 'Recherche  réquise',
      },
    },
    DIALOG_TITLE_ADD: 'Ajouter',
    ADD_BTN: 'Ajouter',
    ADD_NOTES: 'Ajouter des notes',
    DIALOG_TITLE_EDIT: 'Modifer',
    DIALOG_TITLE_DELETE: 'Supprimer',
    DIALOG_TITLE_RESTORE: 'Restaurer',
    DIALOG_TITLE_CRITERIA: 'Plus de filtres',
    DIALOG_TITLE_REGISTER_ONE: 'Inscrire un élève',
    DIALOG_TITLE_REGISTER_MANY: 'Inscrire plusieurs élèves',
    DIALOG_TITLE_DOWNLOAD: 'Télécharger les résultats',
    DIALOG_TITLE_NEWS: 'Envoies des Messages',
    SAVE_BTN: 'Sauvegarder',
    EDIT_BTN: 'Mettre à jour',
    DELETE_BTN: 'Supprimer',
    RESTORE_BTN: 'Restaurer',
    SEARCH_PLUS_BTN: 'filtrer',
    REGISTRATION_OF_MANY: 'Inscrire les élèves',
    CANCEL_BTN: 'Effacer',
    REFRESH_BTN: 'Rafraîchir',
    YES_BTN: 'Oui',
    NO_BTN: 'Non',
    REGISTER_BTN: 'Inscrire',
    REGISTER_ALL_BTN: 'Inscrire tous',
    INITIALIZE_BTN: 'initialiser',
    DOWNLOAD_BTN: 'Télécharger',
    CLONE_BTN: 'Cloner',
    ASSIGN_BTN: 'Assigner',
    RENEW_BTN: 'Renouveler',
    CONFIRMATION_TITLE: 'Confirmation',
    CONFIRMATION_MESSAGE: 'Êtes-vous sûr de vouloir supprimer cette ligne?',
    CONFIRMATION_DATA_MESSAGE: 'Êtes-vous sûr de vouloir supprimer ces données?',
    SEARCH: 'rechercher',
    CONCERN_CLASS: 'La salle de classe',
    CONCERN_ALL_CLASS: 'Les salles de classe',
    CONCERN_SCHOOL: 'L\'établissement',
    CONCERN_STUDENT: 'L\'élève',
    CONCERN_ALL_STUDENT: 'Les élèves',
    STUDENT: {
      TITLE: {
        ADD_TITLE: 'Ajouter un élève',
      },
      MESSAGE_REQUIRED: {
        LNAME: 'Le nom est requis',
        FNAME: 'Le prénom est requis',
        REG_NUMBER: 'Le matricule est requis',
        DOB: 'La date de naissance est requise',
        SCHOOL: 'L\'établissement est requis',
        CLASS: 'La classe est requise',
        PARENT_PHONE_NUMBER: 'Le numéro du parent est requis',
        TYPE_REG_NUMBER: 'Le type de matricule est requis',
      },
      PLACEHOLDER: {
        LNAME: 'Entrez le nom',
        FNAME: 'Entrez le prénom',
        REG_NUMBER: 'Entrez le matricule',
        DOB: 'Sélectionnez la date de naissance',
        SCHOOL: 'Sélectionnez l\'établissement',
        CLASS: 'Sélectionnez la classe',
        PARENT_PHONE_NUMBER: 'Entrez le numéro du parent',
        TYPE_REG_NUMBER: 'Sélectionnez le type de matricule',
      },
      LNAME: 'Nom',
      FNAME: 'Prénom',
      REG_NUMBER: 'Matricule',
      DOB: 'Date de naissance',
      CLASS: 'Classe',
      PARENT_PHONE_NUMBER: 'Numéro du parent',
      TYPE_REG_NUMBER: 'Type de matricule',
      SCHOOL: 'Établissement',
      REGISTRE: 'Registre'
    },
    REGISTRATION: {
      LABEL: {
        TITLE: 'Ajouter une inscription',
      },
      TAB_NOUVEAU: {
        LABEL: {
          TITLE: 'Inscrire un nouveau',
          NOUVEAU: 'Nouvel élève',
        },
        TOOLTIP: {
          NOUVEAU: 'Inscrire un nouvel élève',
        },
        MESSAGE_REQUIRED: {
          LNAME: 'Le nom est requis',
          FNAME: 'Le prénom est requis',
          REG_NUMBER: 'Le matricule est requis',
          DOB: 'La date de naissance est requise',
          SCHOOL: 'L\'établissement est requis',
          CLASS: 'La classe est requise',
          PARENT_PHONE_NUMBER: 'Le numéro du parent est requis',
          TYPE_REG_NUMBER: 'Le type de matricule est requis',
        },
        PLACEHOLDER: {
          LNAME: 'Entrez le nom',
          FNAME: 'Entrez le prénom',
          REG_NUMBER: 'Entrez le matricule',
          DOB: 'Sélectionnez la date de naissance',
          SCHOOL: 'Sélectionnez l\'établissement',
          CLASS: 'Sélectionnez la classe',
          PARENT_PHONE_NUMBER: 'Entrez le numéro du parent',
          TYPE_REG_NUMBER: 'Sélectionnez le type de matricule',
        },
      },
      TAB_CLASSE_SUIVANTE: {
        LABEL: {
          TITLE: "Inscription dans une classe supérieure",
          GO_TO_NEXT_CLASS: "Classe suivante",
        },
        SOURCE_CLASS: "Classe source",
        TARGET_CLASS: "Classe cible",
        SOURCE_SCHOOL_YEAR: "Année scolaire source",
        TARGET_SCHOOL_YEAR: "Année scolaire cible",
        TOOLTIP: {
          GO_TO_NEXT_CLASS: "Inscrire dans la classe suivante",
        },
        MESSAGE_REQUIRED: {
          LNAME: "Le nom de famille est obligatoire",
          FNAME: "Le prénom est obligatoire",
          REG_NUMBER: "Le numéro d'inscription est obligatoire",
          DOB: "La date de naissance est obligatoire",
          SCHOOL: "L'école est obligatoire",
          CLASS: "La classe est obligatoire",
          PARENT_PHONE_NUMBER: "Le numéro de téléphone des parents est obligatoire",
          TYPE_REG_NUMBER: "Le type de numéro d'inscription est obligatoire",
          SOURCE_CLASS: "La classe source est obligatoire",
          TARGET_CLASS: "La classe cible est obligatoire",
          SOURCE_SCHOOL_YEAR: "L'année scolaire source est obligatoire",
          TARGET_SCHOOL_YEAR: "L'année scolaire cible est obligatoire"
        },
        PLACEHOLDER: {
          LNAME: "Saisissez le nom de famille",
          FNAME: "Saisissez le prénom",
          REG_NUMBER: "Saisissez le numéro d'inscription",
          DOB: "Sélectionnez la date de naissance",
          SCHOOL: "Sélectionnez l'école",
          CLASS: "Sélectionnez la classe",
          PARENT_PHONE_NUMBER: "Saisissez le numéro de téléphone des parents",
          TYPE_REG_NUMBER: "Sélectionnez le type de numéro d'inscription",
        },
      },
      TAB_REDOUDLANT: {
        LABEL: {
          TITLE: 'Inscrire un redoublant',
          REDOUBLANT: 'Redoublant',
        },
        TOOLTIP: {
          REDOUBLANT: 'Inscrire un redoublant',
        },
        MESSAGE_REQUIRED: {
          LNAME: 'Le nom est requis',
          FNAME: 'Le prénom est requis',
          REG_NUMBER: 'Le matricule est requis',
          DOB: 'La date de naissance est requise',
          SCHOOL: 'L\'établissement est requis',
          CLASS: 'La classe est requise',
          PARENT_PHONE_NUMBER: 'Le numéro du parent est requis',
          TYPE_REG_NUMBER: 'Le type de matricule est requis',
        },
        PLACEHOLDER: {
          LNAME: 'Entrez le nom',
          FNAME: 'Entrez le prénom',
          REG_NUMBER: 'Entrez le matricule',
          DOB: 'Sélectionnez la date de naissance',
          SCHOOL: 'Sélectionnez l\'établissement',
          CLASS: 'Sélectionnez la classe',
          PARENT_PHONE_NUMBER: 'Entrez le numéro du parent',
          TYPE_REG_NUMBER: 'Sélectionnez le type de matricule',
        },
      },
      MESSAGE_REQUIRED: {
        SOURCE_CLASS: "La classe source est obligatoire",
        TARGET_CLASS: "La classe cible est obligatoire",
        SOURCE_SCHOOL_YEAR: "L'année scolaire source est obligatoire",
        TARGET_SCHOOL_YEAR: "L'année scolaire cible est obligatoire"
      },
      SOURCE_CLASS: "Classe source",
      TARGET_CLASS: "Classe cible",
      SOURCE_SCHOOL_YEAR: "Année scolaire source",
      TARGET_SCHOOL_YEAR: "Année scolaire cible",
      TOGGLE_SELECT_ALL_STUDENT_TITLE: "charger tous les étudiants",
      TOGGLE_UNSELECT_ALL_STUDENT_TITLE: "rechercher et charger les étudiants",
    }

  },
  DASHBOARD: {
    TITLE: 'Tableau de bord: ',
    GREETING: 'Bonjour, Bienvenue',
    TOTAL_STUDENT: 'Total étudiants ',
    TOTAL_PARENT: 'Total parents',
    TOTAL_CLASSE: 'Total classes',
    TOTAL_RETARD: 'Total retards',
  }
  ,
  GRADE: {
    DEVELOPER: 'Développeur',
    TEACHER: 'Enseignant',
    SCHOOL_ADMIN: 'Admin. École',
    PARENT: 'Parent',
    STUDENT: 'Élève',
    STAFF: 'Staff'
  },
  SIDEBAR: {
    TITLE: 'Accueil',
    STUDENT_MANAGEMENT: {
      TITLE: 'Gestion des élèves',
      INSCRIPTION: 'Inscriptions',
      STUDENT: 'Élèves',
      DELAY: 'Retards',
      ABSENCE: 'Absences'
    },
    NOTE_MANAGEMENT: {
      TITLE: 'Gestion des notes',
      NOTE: 'Notes',
      RESULT: 'Résultats'
    },
    COMMUNICATION_MANAGEMENT: {
      TITLE: 'Communication',
      NEWS: 'Actualités',
      EVENT: 'Événements'
    },
    CONFIGURATION: {
      TITLE: 'Paramètres',
      ADMIN: 'Administration',
      CONSOLE: 'Console',
      BASE_PARAMS: 'Config',
    }
  },
  PAGINATOR: {
    ITEMS_PER_PAGE: 'Éléments par page',
    NEXT_PAGE: 'Page suivante',
    PREVIOUS_PAGE: 'Page précédente',
    FIRST_PAGE: 'Première page',
    LAST_PAGE: 'Dernière page',
    ON: 'sur'
  },
  CARD: {
    GRAPH_STAT: {
      DEFAULT_TITLE: 'Graphe',
      SCHOOL_TITLE: 'Graphe établissements',
      SCHOOL_WORK_FORCE_TITLE: 'Effectifs total',
    },
    CLASSE_TITLE: 'Classe',
    SCHOOL_TITLE: 'Ecole',
    SCHOOL_YEAR_TITLE: 'Années scolaire',
    MANAGE_ACTIVE_STUDENT_TITLE: 'Gestion des élèves activés',
    MANAGE_SIMPLE_TITLE: 'Titre par default',
    MANAGE_DESACTIVE_STUDENT_TITLE: 'Gestion des élèves désactivés',
    MANAGE_ACTIVE_INSCRIPTION_TITLE: 'Gestion des inscriptions activés',
    MANAGE_DESACTIVE_INSCRIPTION_TITLE: 'Gestion des inscriptions désactivés',
    TOGGLE_ACTIVE_TITLE: 'Afficher les éléments activés',
    TOGGLE_DESACTIVE_TITLE: 'Afficher les éléments désactivés',
    TOGGLE_CRITERIA_VALUE_TITLE: 'Nettoyer les critères de recherche',

  },
  PLACEHOLDER: {
    SELECT_CLASSE: 'Selectionner une classe',
    SELECT_SCHOOL_YEAR: 'Selectionner une annee scolaire',
    SELECT_SCHOOL: 'Selectionner une école'
  },
  MESSAGE: {
    EMPTY_DATA_SOURCE: 'Aucun résultat trouvé',
    SELECT_CLASSROOM: 'Sélectionner la salle de classe si elle est disponible',
    LNAME_REQUIRED: 'Le nom est obligatoire',
    FNAME_REQUIRED: 'Le prénom est obligatoire',
    REGISTRATION_TYPE_REQUIRED: 'Le type d\'inscription est obligatoire',
    REGISTRATION_NUMBER_REQUIRED: 'Le matricule est obligatoire',
    SCHOOL_REQUIRED: 'L\'établissement est requis',
    CLASSE_REQUIRED: 'La classe est requise',
    DOB_REQUIRED: 'La date de naissance est requise',
    PAYMENT_STATUS_REQUIRED: 'Le statut du paiement est requis',
    PAID_AMOUNT_REQUIRED: 'Frais de scolarite min: 0',
    SCHOOL_FEES_EXECED_AMOUNT: 'N\'exceder pas le reste a payer:',
    SCHOOL_FEES_INVALID_AMOUNT: 'est un montant invalide.'
  },
  LABEL: {
    STUDENT: {
      LNAME: 'Nom',
      FNAME: 'Prénom',
      LNAME_FNAME: 'Nom & Prénom',
      REG_TYPE: 'Type de matricule',
      REG_NUMBER: 'Matricule',
      TUTOR_PHONE: 'Numéro du parent',
      DOB: 'Date de naissance',
      SCHOOL: 'Ecole',
      AUTO_REG_NUMBER: 'Matricule automatique',
      MANUAL_REG_NUMBER: 'Matricule manuel',
      FORMER_CLASSROOM: 'Ancienne salle de classe',
      NEW_CLASSROOM: 'Nouvelle salle de classe',
      STUDENT_CLASSROOM_STATUS: 'Statut de l\'élève',
      LOAD_DATA_FROM: 'Charger les données de',
      SELECT_STUDENT: 'Sélectionner un élève'
    },
    DELAY: {
      IS_DELAY: 'En retard',
      IS_NOT_DELAY: 'A l\'heure'
    },
    FRANCO: 'Francophone',
    ANGLO: 'Anglophone',
    TRIMESTER1: 'Trimestre 1',
    TRIMESTER2: 'Trimestre 2',
    TRIMESTER3: 'Trimestre 3',
    SEQUENCE1: 'Sequence 1',
    SEQUENCE2: 'Sequence 2',
    SEQUENCE3: 'Sequence 3',
    SEQUENCE4: 'Sequence 4',
    SEQUENCE5: 'Sequence 5',
    SEQUENCE6: 'Sequence 6',
    IS_INITIALIZED: 'Est initialisé',
    IS_NOT_INITIALIZED: 'Non initialisé',
    IS_EXAM_CLASSROOM: 'Classe d\'examem',
    IS_NOT_EXAM_CLASSROOM: 'Classe normal',
    ENSEIGNANT: 'Enseignant',
    STAFF: 'Staff',
    IS_SCHOOL_ADMIN: "Administrateur école",
    IS_NOT_SCHOOL_ADMIN: "Non administrateur école",
    IS_CURRENT: 'En cours',
    IS_NOT_CURRENT: 'Pas en cours',
    IS_CHECK_IN: 'Entre vérifiée ',
    IS_CHECK_OUT: 'Sortie verifiée',
    BOTH_ARE_CHECK: 'Entrer et sortie verifiées',
    CLASSE: 'Classe',
    PAYMENT_STATUS: 'Status du paiement',
    PAID_AMOUNT: 'Versement de',
    ALREADY_PAID: 'Frais déjà payé'

  },
  DATASOURCE: {
    STUDENT: {
      AVATAR: 'Avatar',
      LAST_NAME: 'Nom',
      FIRST_NAME: 'Prénom',
      PARENT_PHONE_NUMBER: 'Tél parent',
      REG_NUMBER: 'Matricule',
      UID: 'UID',
      PAYMENT: 'Paiement',
      ACTION: 'Actions'
    },
    INSCRIPTION: {
      ACTION: 'Actions'
    }
  },
  SPINNER: {
    LOADING: {
      MESSAGE: 'Chargement des données...',
      ICON: '⏳', // Icône de sablier
    },
    EMPTY: {
      MESSAGE: 'Aucune donnée disponible', // Message pour données vides
      ICON: '📭', // Boîte vide
    },
    FAILED: {
      MESSAGE: 'Données non chargées',
      ICON: '❌', // Icône d'erreur
    },
    SUCCESS: {
      MESSAGE: 'Données chargées avec succès !',
      ICON: '✅', // Icône de succès
    },
  },
  ERROR_PAGE: {
    ERROR_401: {
      TITLE: 'Accès non autorisé',
      DESCRIPTION: 'Vous n\'êtes pas autorisé à accéder à cette ressource. Veuillez vous connecter.',
      INSTRUCTION: 'Se connecter',
      HELP: 'Besoin d\'aide ?',
      SIGN_IN: 'Se connecter',
    },
    ERROR_404: {
      TITLE: 'Page non trouvée',
      DESCRIPTION: 'La page que vous recherchez n\'existe pas. Vérifiez l\'URL ou retournez à la page d\'accueil.',
      INSTRUCTION: 'Retour à l\'accueil',
      HELP: 'Besoin d\'aide ?',
      SIGN_IN: 'Se connecter',
    },
    ERROR_500: {
      TITLE: 'On dirait que vous êtes perdu',
      DESCRIPTION: 'Oups, quelque chose a mal tourné. Veuillez réessayer dans quelques instants.',
      INSTRUCTION: 'Aller au tableau de bord',
      HELP: 'Besoin d\'aide ?',
      SIGN_IN: 'Se connecter',
    },
    ERROR_403: {
      TITLE: 'Accès interdit',
      DESCRIPTION: 'Vous n\'avez pas les droits nécessaires pour accéder à cette ressource.',
      INSTRUCTION: 'Retourner à la page précédente',
      HELP: 'Besoin d\'aide ?',
      SIGN_IN: 'Se connecter',
    },
    ERROR_408: {
      TITLE: 'Délai d\'attente dépassé',
      DESCRIPTION: 'La demande a pris trop de temps. Veuillez réessayer.',
      INSTRUCTION: 'Réessayer',
      HELP: 'Besoin d\'aide ?',
      SIGN_IN: 'Se connecter',
    },
    ERROR_502: {
      TITLE: 'Mauvaise passerelle',
      DESCRIPTION: 'Le serveur de la passerelle a reçu une réponse invalide d\'un autre serveur.',
      INSTRUCTION: 'Réessayer',
      HELP: 'Besoin d\'aide ?',
      SIGN_IN: 'Se connecter',
    },
    ERROR_503: {
      TITLE: 'Service indisponible',
      DESCRIPTION: 'Le service est temporairement hors ligne. Veuillez réessayer plus tard.',
      INSTRUCTION: 'Réessayer plus tard',
      HELP: 'Besoin d\'aide ?',
      SIGN_IN: 'Se connecter',
    },
    ERROR_504: {
      TITLE: 'Délai d\'attente de la passerelle expiré',
      DESCRIPTION: 'Le serveur n\'a pas répondu dans le délai imparti.',
      INSTRUCTION: 'Réessayer',
      HELP: 'Besoin d\'aide ?',
      SIGN_IN: 'Se connecter',
    },

  },
  CONSOLE: {
    ICON: {
      EDIT: 'modifer',
      DELETE: 'supprimer',
      INIT_AT: 'initialisés',
      ADD_SECTION: 'ajouter section',
      ADD_LEVEL: 'initialiser les niveaux',
      ADD_CLASS: 'cloner une classe',
      ASSIGN_CLASS: 'assiger des classes',
    },
    HEADER: {
      TAB_ONE_TITLE: 'Conf. basique',
      TAB_TWO_TITLE: 'Conf. moyen',
      TAB_THREE_TITLE: 'Conf. elevé',
      TAB_FOUR_TITLE: 'Conf. complexe',
    },
    CARD: {
      NEWS_TITLE: 'Message',
      EVENT_TITLE: 'Evènements',
      NOTE_TITLE: 'Notes',
      RESULT_TITLE: 'Résultats',
      DELAY_TITLE: 'Retards',
      STUDENT_TITLE: 'Élèves',
      DETAIL_TITLE: 'Détails',
      INSCRIPTION_TITLE: 'Inscriptions',
      ABSENCE_TITLE: 'Absences',
      SECTION_TITLE: 'Sections',
      SCHOOL_TITLE: 'Établissements',
      SCHOOL_YEAR_TITLE: 'Années scolaires',
      PROFIL_TITLE: 'Profils',
      TRIMESTRE_TITLE: 'Trimestres',
      LEVEL_TITLE: 'Niveaux',
      OPTION_TITLE: 'Options',
      EVALUATION_TITLE: 'Évaluations',
      CLASSE_TITLE: 'Classes',
      COURSE_TITLE: 'Matières',
      GROUP_TITLE: 'Groupes',
      STAFF_TITLE: 'Équipe technique',
      SEQUENCE_TITLE: 'Séquences',
      TEACHER_TITLE: 'Enseignants',
      TUTOR_TITLE: 'Parents',
      VERIFICATION_TITLE: 'Vérification',
      PROGRAM_TITLE: 'Programmes',
      PLANNING_TITLE: 'Planning horaire',
      PLAGE_TITLE: 'Plages horaires',
    },
    DIALOG: {
      CLASS: {
        TITLE: {
          ADD_TITLE: 'Ajouter une classe',
          EDIT_TITLE: 'Modifier cette classe',
          DELETE_TITLE: 'Supprimer cette classe'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'L\'intitulé est requis',
          CODE: 'Le code est requis',
          SCHOOL: 'L\'établissement est requis',
          HARDWAREID: 'Hardware ID  requis',
          LEVEL: 'Niveau  requis',
          OPTION: 'Option  requis',
          CLASSROOM_TYPE: 'Type de classe  requis',
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom classe',
          CODE: 'Code',
          HARDWAREID: 'Hardware ID'
        },
        CHOOSE_CLASS: 'Selectionner la classe',
        INTITULE: 'Désignation',
        HARDWAREID: 'Hardware ID',
        CODE: 'Code',
        LEVEL: 'Niveau',
        OPTION: 'Option',
        SCHOOL: 'Etablissement',
        INITIALIZE_COURSE: 'Statut des cours initialisés',
        CLASSROOM_TYPE: 'Type de classe',
        REGISTRE: 'Registre'
      },
      SECTION: {
        TITLE: {
          ADD_TITLE: 'Ajouter une section',
          EDIT_TITLE: 'Modifier cette section',
          DELETE_TITLE: 'Supprimer cette section'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'L\'intitulé est requis',
          CODE: 'Le code est requis',
          SCHOOL: 'L\'établissement est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom section',
          CODE: 'Code'
        },
        CHOOSE_SECTION: 'Selectionner section',
        INTITULE: 'Désignation',
        CODE: 'Code',
        SCHOOL: 'Etablissement',
        INITIALIZE_COURSE: 'Statut des cours initialisés',
        REGISTRE: 'Registre'
      },
      GROUP: {
        TITLE: {
          ADD_TITLE: 'Ajouter un group',
          EDIT_TITLE: 'Modifier ce group',
          DELETE_TITLE: 'Supprimer ce group'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'L\'intitulé est requis',
          CODE: 'Le code est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom group',
          CODE: 'Code'
        },
        CHOOSE_GROUP: 'Selectionner group',
        INTITULE: 'Désignation',
        CODE: 'Code',
      },
      QUATER: {
        TITLE: {
          ADD_TITLE: 'Ajouter un trimestre',
          EDIT_TITLE: 'Modifier ce trimestre',
          DELETE_TITLE: 'Supprimer ce trimestre'
        },
        MESSAGE_REQUIRED: {
          TITLE: 'Le titre est requis',
          CODE: 'Le code est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex. nom du trimestre',
          CODE: 'Code'
        },
        CHOOSE_GROUP: 'Choisir le groupe',
        INTITULE: 'Titre',
        CODE: 'Code',
      },
      PROFIL: {
        TITLE: {
          ADD_TITLE: 'Ajouter un profil',
          EDIT_TITLE: 'Modifier ce profil',
          DELETE_TITLE: 'Supprimer ce profil'
        },
        MESSAGE_REQUIRED: {
          TITLE: 'Le titre est requis',
          INITIALIZED: 'est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex. nom du profil',
          INITIALIZED: 'Est initialisé'
        },
        INTITULE: 'Titre',
        INITIALIZED: 'Est initialisé',
      },
      LEVEL: {
        TITLE: {
          ADD_TITLE: 'Ajouter un niveau',
          EDIT_TITLE: 'Modifier ce niveau',
          DELETE_TITLE: 'Supprimer ce niveau'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'Le titre est requis',
          CODE: 'Le code est requis',
          SECTION: 'La section est requise',
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom du niveau',
          CODE: 'Code'
        },
        CHOOSE_SECTION: 'Sélectionner la section',
        INTITULE: 'Titre',
        CODE: 'Code',
        SECTION: 'Section'
      },
      SCHOOL: {
        TITLE: {
          ADD_TITLE: 'Ajouter un établissement',
          EDIT_TITLE: 'Modifier cet établissement',
          DELETE_TITLE: 'Supprimer cet établissement',
          INIT_COURSE_TITLE: 'Initialisation des cours',
          CLONE_CLASS_TITLE: 'Clonage de classe',
        },
        MESSAGE_REQUIRED: {
          SECTION: 'Le type de section est requis',
          SCHOOL_TYPE: 'Le type d\'établissement est requis',
          ADRESS: 'L\'adresse est requise',
          PO_BOX: 'La boite postale est requise',
          PICTURE: 'L\'image est requise',
          SERVER_LINK: 'Le lien du serveur est requis',
          URL: 'L\'URL est requise',
          DEFAULT: 'Le type par défaut est requis',
          INTITULE: 'L\'intitulé est requis',
          SOURCE_SCHOOL: 'Etablissement source requis',
          SOURCE_SECTION: 'Section souce requise',
          TARGET_SECTION: 'Section cible requise',
        },
        PLACEHOLDER: {
          ADRESS: 'ex: adresse',
          PO_BOX: 'ex: BP 123',
          PICTURE: 'ex: image',
          SERVER_LINK: 'ex: www.domaine.com',
          URL: 'ex: url',
          INTITULE: 'ex: nom établissement',
          CHOOSE_SCHOOL: 'Etablissement'
        },
        INTITULE: 'Désignation',
        DEFAULT: 'Type par défaut',
        URL: 'URL',
        PO_BOX: 'Boite postal',
        PICTURE: 'Image',
        SERVER_LINK: 'Lien HTTP',
        ADRESS: 'Adresse',
        SECTION: 'Section',
        SCHOOL_TYPE: 'Type d\'établissement',
        IS_COURSE_INITIALIZE: 'sont initialisés',
        IS_NOT_COURSE_INITIALIZE: 'pas initialisés',
        NO_SECTION_AVAILABLE: 'Aucune section disponible.',
        CREATE_SECTION_PROMPT: 'Vous pouvez créer de nouvelles sections dans le box Section.',
        SOURCE_SCHOOL: 'Etablissement source',
        TARGET_SCHOOL: 'Etablissement cible',
        SOURCE_SECTION: 'Section source',
        TARGET_SECTION: 'Section cible'
      },
      SCHOOL_YEAR: {
        TITLE: {
          ADD_TITLE: 'Ajouter une année scolaire',
          EDIT_TITLE: 'Modifier cet année scolaire',
          DELETE_TITLE: 'Supprimer cet année scolaire'
        },
        MESSAGE_REQUIRED: {
          FROM_YEAR: 'Le début d\'année est requis',
          TO_YEAR: 'La fin d\'année  est requise',
          CURRENT_YEAR: 'Le type d\'année est requis',
          SOURCE_SCHOOL_YEAR: 'Etablissement source requis',
        },
        PLACEHOLDER: {
          FROM_YEAR: 'ex: YYYY',
          TO_YEAR: 'ex: YYYY',
        },
        FROM_YEAR: 'Début d\'année',
        TO_YEAR: 'Fin d\'année',
        START_DATE: 'Date debut',
        END_DATE: 'Date fin',
        CURRENT_YEAR: 'Cette année est',
        CHOOSE_SCHOOL_YEAR: 'Année scolaire',
        INTITULE: 'Année scolaire',
        SOURCE_SCHOOL_YEAR: 'Année scolaire source',
        TARGET_SCHOOL_YEAR: 'Année scolaire cible',
      },
      SEQUENCE: {
        TITLE: {
          ADD_TITLE: 'Ajouter une sequence',
          EDIT_TITLE: 'Modifier cette sequence',
          DELETE_TITLE: 'Supprimer cette sequence'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'L\'intitulé est requis',
          CODE: 'Le code est requis',
          QUARTER: 'Le trimestre est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom sequence',
          CODE: 'Code'
        },
        CHOOSE_SEQUENCE: 'Selectionner sequence',
        INTITULE: 'Désignation',
        CODE: 'Code',
        QUARTER: 'Trimestre'
      },
      OPTION: {
        TITLE: {
          ADD_TITLE: 'Ajouter une option',
          EDIT_TITLE: 'Modifier cette option',
          DELETE_TITLE: 'Supprimer cette option'
        },
        MESSAGE_REQUIRED: {
          INTITULE: "L\'intitulé est requis",
          SECTION: 'La section est requise',
          LEVEL: 'Le niveau est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom option',
        },
        CHOOSE_OPTION: 'Selectionner sequence',
        INTITULE: 'Désignation',
        SECTION: 'Section',
        QUARTER: 'Level'
      },
      COURSE: {
        TITLE: {
          ADD_TITLE: 'Ajouter une matière',
          EDIT_TITLE: 'Modifier cette matière',
          DELETE_TITLE: 'Supprimer cette matière'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'Le titre est requis',
          CODE: 'Le code est requis',
          GROUP: 'Le group est requis',
          INITIALIZED: 'est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom du niveau',
          CODE: 'Code'
        },
        CHOOSE_COURSE: 'Sélectionner la matière',
        INTITULE: 'Titre',
        CODE: 'Code',
        GROUP: 'Groupe',
        INITIALIZED: 'la matiere est'
      },
      TEACHER: {
        TITLE: {
          ADD_TITLE: 'Ajouter un enseignant',
          RENEW_TITLE: 'Renouvèlement des enseignants',
          EDIT_TITLE: 'Modifier cet enseignant',
          DELETE_TITLE: 'Supprimer l\'enseignant',
          ASSIGN_CLASS_TITLE: 'Affectation de classe',
        },
        MESSAGE_REQUIRED: {
          IS_SCHOOL_ADMIN: 'Valeur requise',
          FNAME: 'Prénom requis',
          LNAME: 'Nom requis',
          PHONE_NUMBER: 'Numéro de téléphone requis',
          REG_NUMBER: 'Registration number is required',
        },
        PLACEHOLDER: {
          FNAME: 'ex. John',
          LNAME: 'ex. Doe',
          PHONE_NUMBER: 'ex. 00000000',
          REG_NUMBER: 'ex. XQSUI',
        },
        FNAME: 'Prénom',
        LNAME: 'Nom',
        PHONE_NUMBER: 'Téléphone',
        IS_SCHOOL_ADMIN: 'Administrateur école',
        REG_NUMBER: 'Matricule',
        REG_NUMBER_AUTO: 'Matricule automatique',
        REG_NUMBER_MAN: 'Matricule manuel',
      },
      PLANNING: {
        TITLE: {
          ADD_UPDATE_TITLE: 'Ajouter ou modifier le planning',
          DELETE_TITLE: 'Supprimer planning',
        },
      },

    },
    MENU: {
      DOWNLOAD: 'Télécharger',
      REFRESH: 'Actualiser',
      ADD: 'Ajouter',
      EDIT: 'Editer',
      DELETE: 'Supprimer',
      SEARCH: 'Rechercher',
      ASSOCIATE: 'Associer',
      ORDER: 'Ordonner',
      Clone: 'Cloner',
      RENEW: 'Renouveler',
    },
    MESSAGE: {
      EMPTY_DATA: ' vide!',
      EMPTY_SCHOOL_DATA: 'Le contenu de l\'école est vide.',
    }
  },
  CONFIG: {
    CARD: {
      TITLE: 'Configuration générale',
      BLOC: {
        BLOC1: {
          TITLE: 'Bloc 1 titre ici'
        }
      }
    },
    DIALOG: {
      CLASS: {
        TITLE: {
          ADD_TITLE: 'Ajouter une classe',
          EDIT_TITLE: 'Modifier cette classe',
          DELETE_TITLE: 'Supprimer cette classe'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'L\'intitulé est requis',
          CODE: 'Le code est requis',
          SCHOOL: 'L\'établissement est requis',
          HARDWAREID: 'Hardware ID  requis',
          LEVEL: 'Niveau  requis',
          OPTION: 'Option  requis',
          CLASSROOM_TYPE: 'Type de classe  requis',
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom classe',
          CODE: 'Code',
          HARDWAREID: 'Hardware ID'
        },
        CHOOSE_CLASS: 'Selectionner la classe',
        INTITULE: 'Désignation',
        HARDWAREID: 'Hardware ID',
        CODE: 'Code',
        LEVEL: 'Niveau',
        OPTION: 'Option',
        SCHOOL: 'Etablissement',
        INITIALIZE_COURSE: 'Statut des cours initialisés',
        CLASSROOM_TYPE: 'Type de classe',
        REGISTRE: 'Registre'
      },
      SECTION: {
        TITLE: {
          ADD_TITLE: 'Ajouter une section',
          EDIT_TITLE: 'Modifier cette section',
          DELETE_TITLE: 'Supprimer cette section'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'L\'intitulé est requis',
          CODE: 'Le code est requis',
          SCHOOL: 'L\'établissement est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom section',
          CODE: 'Code'
        },
        CHOOSE_SECTION: 'Selectionner section',
        INTITULE: 'Désignation',
        CODE: 'Code',
        SCHOOL: 'Etablissement',
        INITIALIZE_COURSE: 'Statut des cours initialisés',
        REGISTRE: 'Registre'
      },
      GROUP: {
        TITLE: {
          ADD_TITLE: 'Ajouter un group',
          EDIT_TITLE: 'Modifier ce group',
          DELETE_TITLE: 'Supprimer ce group'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'L\'intitulé est requis',
          CODE: 'Le code est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom group',
          CODE: 'Code'
        },
        CHOOSE_GROUP: 'Selectionner group',
        INTITULE: 'Désignation',
        CODE: 'Code',
      },
      QUATER: {
        TITLE: {
          ADD_TITLE: 'Ajouter un trimestre',
          EDIT_TITLE: 'Modifier ce trimestre',
          DELETE_TITLE: 'Supprimer ce trimestre'
        },
        MESSAGE_REQUIRED: {
          TITLE: 'Le titre est requis',
          CODE: 'Le code est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex. nom du trimestre',
          CODE: 'Code'
        },
        CHOOSE_GROUP: 'Choisir le groupe',
        INTITULE: 'Titre',
        CODE: 'Code',
      },
      PROFIL: {
        TITLE: {
          ADD_TITLE: 'Ajouter un profil',
          EDIT_TITLE: 'Modifier ce profil',
          DELETE_TITLE: 'Supprimer ce profil'
        },
        MESSAGE_REQUIRED: {
          TITLE: 'Le titre est requis',
          INITIALIZED: 'est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex. nom du profil',
          INITIALIZED: 'Est initialisé'
        },
        INTITULE: 'Titre',
        INITIALIZED: 'Est initialisé',
      },
      LEVEL: {
        TITLE: {
          ADD_TITLE: 'Ajouter un niveau',
          EDIT_TITLE: 'Modifier ce niveau',
          DELETE_TITLE: 'Supprimer ce niveau'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'Le titre est requis',
          CODE: 'Le code est requis',
          SECTION: 'La section est requise',
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom du niveau',
          CODE: 'Code'
        },
        CHOOSE_SECTION: 'Sélectionner la section',
        INTITULE: 'Titre',
        CODE: 'Code',
        SECTION: 'Section'
      },
      SCHOOL: {
        TITLE: {
          ADD_TITLE: 'Ajouter un établissement',
          EDIT_TITLE: 'Modifier cet établissement',
          DELETE_TITLE: 'Supprimer cet établissement',
          INIT_COURSE_TITLE: 'Initialisation des cours',
          CLONE_CLASS_TITLE: 'Clonage de classe',
        },
        MESSAGE_REQUIRED: {
          SECTION: 'Le type de section est requis',
          SCHOOL_TYPE: 'Le type d\'établissement est requis',
          ADRESS: 'L\'adresse est requise',
          PO_BOX: 'La boite postale est requise',
          PICTURE: 'L\'image est requise',
          SERVER_LINK: 'Le lien du serveur est requis',
          URL: 'L\'URL est requise',
          DEFAULT: 'Le type par défaut est requis',
          INTITULE: 'L\'intitulé est requis',
          SOURCE_SCHOOL: 'Etablissement source requis',
          SOURCE_SECTION: 'Section souce requise',
          TARGET_SECTION: 'Section cible requise',
        },
        PLACEHOLDER: {
          ADRESS: 'ex: adresse',
          PO_BOX: 'ex: BP 123',
          PICTURE: 'ex: image',
          SERVER_LINK: 'ex: www.domaine.com',
          URL: 'ex: url',
          INTITULE: 'ex: nom établissement',
          CHOOSE_SCHOOL: 'Etablissement'
        },
        INTITULE: 'Désignation',
        DEFAULT: 'Type par défaut',
        URL: 'URL',
        PO_BOX: 'Boite postal',
        PICTURE: 'Image',
        SERVER_LINK: 'Lien HTTP',
        ADRESS: 'Adresse',
        SECTION: 'Section',
        SCHOOL_TYPE: 'Type d\'établissement',
        IS_COURSE_INITIALIZE: 'sont initialisés',
        IS_NOT_COURSE_INITIALIZE: 'pas initialisés',
        NO_SECTION_AVAILABLE: 'Aucune section disponible.',
        CREATE_SECTION_PROMPT: 'Vous pouvez créer de nouvelles sections dans le box Section.',
        SOURCE_SCHOOL: 'Etablissement source',
        TARGET_SCHOOL: 'Etablissement cible',
        SOURCE_SECTION: 'Section source',
        TARGET_SECTION: 'Section cible'
      },
      SCHOOL_YEAR: {
        TITLE: {
          ADD_TITLE: 'Ajouter une année scolaire',
          EDIT_TITLE: 'Modifier cet année scolaire',
          DELETE_TITLE: 'Supprimer cet année scolaire'
        },
        MESSAGE_REQUIRED: {
          FROM_YEAR: 'Le début d\'année est requis',
          TO_YEAR: 'La fin d\'année  est requise',
          CURRENT_YEAR: 'Le type d\'année est requis',
          SOURCE_SCHOOL_YEAR: 'Etablissement source requis',
        },
        PLACEHOLDER: {
          FROM_YEAR: 'ex: YYYY',
          TO_YEAR: 'ex: YYYY',
        },
        FROM_YEAR: 'Début d\'année',
        TO_YEAR: 'Fin d\'année',
        START_DATE: 'Date debut',
        END_DATE: 'Date fin',
        CURRENT_YEAR: 'Cette année est',
        CHOOSE_SCHOOL_YEAR: 'Année scolaire',
        INTITULE: 'Année scolaire',
        SOURCE_SCHOOL_YEAR: 'Année scolaire source',
        TARGET_SCHOOL_YEAR: 'Année scolaire cible',
      },
      SEQUENCE: {
        TITLE: {
          ADD_TITLE: 'Ajouter une sequence',
          EDIT_TITLE: 'Modifier cette sequence',
          DELETE_TITLE: 'Supprimer cette sequence'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'L\'intitulé est requis',
          CODE: 'Le code est requis',
          QUARTER: 'Le trimestre est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom sequence',
          CODE: 'Code'
        },
        CHOOSE_SEQUENCE: 'Selectionner sequence',
        INTITULE: 'Désignation',
        CODE: 'Code',
        QUARTER: 'Trimestre'
      },
      OPTION: {
        TITLE: {
          ADD_TITLE: 'Ajouter une option',
          EDIT_TITLE: 'Modifier cette option',
          DELETE_TITLE: 'Supprimer cette option'
        },
        MESSAGE_REQUIRED: {
          INTITULE: "L\'intitulé est requis",
          SECTION: 'La section est requise',
          LEVEL: 'Le niveau est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom option',
        },
        CHOOSE_OPTION: 'Selectionner sequence',
        INTITULE: 'Désignation',
        SECTION: 'Section',
        QUARTER: 'Level'
      },
      COURSE: {
        TITLE: {
          ADD_TITLE: 'Ajouter une matière',
          EDIT_TITLE: 'Modifier cette matière',
          DELETE_TITLE: 'Supprimer cette matière'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'Le titre est requis',
          CODE: 'Le code est requis',
          GROUP: 'Le group est requis',
          INITIALIZED: 'est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom du niveau',
          CODE: 'Code'
        },
        CHOOSE_COURSE: 'Sélectionner la matière',
        INTITULE: 'Titre',
        CODE: 'Code',
        GROUP: 'Groupe',
        INITIALIZED: 'la matiere est'
      },
      TEACHER: {
        TITLE: {
          ADD_TITLE: 'Ajouter un enseignant',
          RENEW_TITLE: 'Renouvèlement des enseignants',
          EDIT_TITLE: 'Modifier cet enseignant',
          DELETE_TITLE: 'Supprimer l\'enseignant',
          ASSIGN_CLASS_TITLE: 'Affectation de classe',
        },
        MESSAGE_REQUIRED: {
          IS_SCHOOL_ADMIN: 'Valeur requise',
          FNAME: 'Prénom requis',
          LNAME: 'Nom requis',
          PHONE_NUMBER: 'Numéro de téléphone requis',
          REG_NUMBER: 'Registration number is required',
        },
        PLACEHOLDER: {
          FNAME: 'ex. John',
          LNAME: 'ex. Doe',
          PHONE_NUMBER: 'ex. 00000000',
          REG_NUMBER: 'ex. XQSUI',
        },
        FNAME: 'Prénom',
        LNAME: 'Nom',
        PHONE_NUMBER: 'Téléphone',
        IS_SCHOOL_ADMIN: 'Administrateur école',
        REG_NUMBER: 'Matricule',
        REG_NUMBER_AUTO: 'Matricule automatique',
        REG_NUMBER_MAN: 'Matricule manuel',
      },
      PLANNING: {
        TITLE: {
          ADD_UPDATE_TITLE: 'Ajouter ou modifier le planning',
          DELETE_TITLE: 'Supprimer planning',
        },
      },

    },
    MENU: {
      DOWNLOAD: 'Télécharger',
      REFRESH: 'Actualiser',
      ADD: 'Ajouter',
      EDIT: 'Editer',
      DELETE: 'Supprimer',
      SEARCH: 'Rechercher',
      ASSOCIATE: 'Associer',
      ORDER: 'Ordonner',
      Clone: 'Cloner',
      RENEW: 'Renouveler',
    },
    MESSAGE: {
      EMPTY_DATA: ' vide!',
      EMPTY_SCHOOL_DATA: 'Le contenu de l\'école est vide.',
    }
  },
  // Ajoutez d'autres sections et constantes au besoin
};
