export const WordingEn = {
  FILE_ARIANE: {
    STUDENT: {
      TITLE: 'Student',
      HOME: 'Home',
      ITEM: 'Student management',
      ITEM_ACTIVE: 'students',
    },
    INSCRIPTION: {
      TITLE: 'Registration',
      HOME: 'Home',
      ITEM: 'Student Management',
      ITEM_ACTIVE: 'Registration',
    },
    SETTING: {
      TITLE: 'Settings',
      HOME: 'Home',
      ITEM: 'Management',
      ITEM_ACTIVE: 'console',
    },
    CONFIG: {
      TITLE: 'Settings',
      HOME: 'Home',
      ITEM: 'Management',
      ITEM_ACTIVE: 'config',
    },
  },
  LOGIN: {
    WELCOME_MESSAGE: 'Welcome to our app!',
    USERNAME: 'Username',
    PASSWORD: 'Password',
    SIGN_IN: 'Sign In',
    FORGOT_PASSWORD: 'Forgot Password?',
  },
  SIGNUP: {
    CREATE_ACCOUNT: 'Create Account',
    EMAIL: 'Email',
    CONFIRM_PASSWORD: 'Confirm Password',
    TERMS_CONDITIONS: 'I agree to the Terms and Conditions',
    SIGN_UP: 'Sign Up',
  },
  FORGOT_PASSWORD: {
    RESET_PASSWORD: 'Reset Password',
    ENTER_EMAIL: 'Enter your email address',
    SEND_RESET_LINK: 'Send Reset Link',
  },
  FORM: {
    LABEL: {
      REG_NUMBER: 'Reg number: ',
      SEARCH_VALUE: 'Search',
      PLACEHOLDER: {
        SEARCH_VALUE: 'Search',
      },
      REQUIRED: {
        SEARCH_VALUE: 'Search value is required',
      },
    },
    DIALOG_TITLE_ADD: 'Add',
    ADD_BTN: 'Add',
    ADD_NOTES: 'Add notes',
    DIALOG_TITLE_EDIT: 'Edit',
    DIALOG_TITLE_DELETE: 'Delete',
    DIALOG_TITLE_RESTORE: 'Restore',
    DIALOG_TITLE_CRITERIA: 'More filters',
    DIALOG_TITLE_REGISTER_ONE: 'Register a student',
    DIALOG_TITLE_REGISTER_MANY: 'Register students',
    DIALOG_TITLE_DOWNLOAD: 'Download Results',
    DIALOG_TITLE_NEWS: 'Send messages',
    SAVE_BTN: 'Save',
    EDIT_BTN: 'Edit',
    DELETE_BTN: 'Delete',
    RESTORE_BTN: 'Restore',
    SEARCH_PLUS_BTN: 'filter',
    REGISTRATION_OF_MANY: 'Register students',
    CANCEL_BTN: 'Cancel',
    REFRESH_BTN: 'Refresh',
    YES_BTN: 'Yes',
    NO_BTN: 'No',
    REGISTER_BTN: 'Register',
    REGISTER_ALL_BTN: 'Register all',
    INITIALIZE_BTN: 'Initialize',
    DOWNLOAD_BTN: 'Download',
    CLONE_BTN: 'Clone',
    ASSIGN_BTN: 'Assign',
    RENEW_BTN: 'Renew',
    CONFIRMATION_TITLE: 'Confirmation',
    CONFIRMATION_MESSAGE: 'Are you sure you want to delete this line?',
    CONFIRMATION_DATA_MESSAGE: 'Are you sure you want to delete this data?',
    SEARCH: 'search',
    CONCERN_CLASS: 'The classroom',
    CONCERN_ALL_CLASS: 'All classrooms',
    CONCERN_SCHOOL: 'The school',
    CONCERN_STUDENT: 'The student',
    CONCERN_ALL_STUDENT: 'All students',
    STUDENT: {
      TITLE: {
        ADD_TITLE: 'Add a student',
      },
      MESSAGE_REQUIRED: {
        LNAME: 'Last name is required',
        FNAME: 'First name is required',
        REG_NUMBER: 'Registration number is required',
        DOB: 'Date of birth is required',
        SCHOOL: 'School is required',
        CLASS: 'Class is required',
        PARENT_PHONE_NUMBER: 'Parent\'s phone number is required',
        TYPE_REG_NUMBER: 'Registration type is required',
      },
      PLACEHOLDER: {
        LNAME: 'Enter last name',
        FNAME: 'Enter first name',
        REG_NUMBER: 'Enter registration number',
        DOB: 'Select date of birth',
        SCHOOL: 'Select school',
        CLASS: 'Select class',
        PARENT_PHONE_NUMBER: 'Enter parent\'s phone number',
        TYPE_REG_NUMBER: 'Select registration type',
      },
      LNAME: 'Last name',
      FNAME: 'First name',
      REG_NUMBER: 'Registration number',
      DOB: 'Date of birth',
      CLASS: 'Class',
      PARENT_PHONE_NUMBER: 'Parent\'s phone number',
      TYPE_REG_NUMBER: 'Registration type',
      SCHOOL: 'School',
      REGISTRE: 'Register'
    },
    REGISTRATION: {
      LABEL: {
        TITLE: 'Add a registration',
      },
      TAB_NOUVEAU: {
        LABEL: {
          TITLE: 'Register a new student',
          NOUVEAU: 'New student',
        },
        TOOLTIP: {
          NOUVEAU: 'Register a new student',
        },
        MESSAGE_REQUIRED: {
          LNAME: 'Last name is required',
          FNAME: 'First name is required',
          REG_NUMBER: 'Registration number is required',
          DOB: 'Date of birth is required',
          SCHOOL: 'School is required',
          CLASS: 'Class is required',
          PARENT_PHONE_NUMBER: 'Parent\'s phone number is required',
          TYPE_REG_NUMBER: 'Type of registration number is required',
        },
        PLACEHOLDER: {
          LNAME: 'Enter last name',
          FNAME: 'Enter first name',
          REG_NUMBER: 'Enter registration number',
          DOB: 'Select date of birth',
          SCHOOL: 'Select school',
          CLASS: 'Select class',
          PARENT_PHONE_NUMBER: 'Enter parent\'s phone number',
          TYPE_REG_NUMBER: 'Select type of registration number',
        },
      },
      TAB_CLASSE_SUIVANTE: {
        LABEL: {
          TITLE: 'Register in a higher class',
          GO_TO_NEXT_CLASS: 'Next class',
        },
        SOURCE_CLASS: 'Source classroom',
        TARGET_CLASS: 'Target classroom',
        SOURCE_SCHOOL_YEAR: 'Source school year',
        TARGET_SCHOOL_YEAR: 'Target school year',
        TOOLTIP: {
          GO_TO_NEXT_CLASS: 'Register in the next class',
        },
        MESSAGE_REQUIRED: {
          LNAME: 'Last name is required',
          FNAME: 'First name is required',
          REG_NUMBER: 'Registration number is required',
          DOB: 'Date of birth is required',
          SCHOOL: 'School is required',
          CLASS: 'Class is required',
          PARENT_PHONE_NUMBER: 'Parent\'s phone number is required',
          TYPE_REG_NUMBER: 'Type of registration number is required',
          SOURCE_CLASS: 'Source classroom is required',
          TARGET_CLASS: 'Target classroom is required',
          SOURCE_SCHOOL_YEAR: 'Source school year is required',
          TARGET_SCHOOL_YEAR: 'Target school year is required'
        },
        PLACEHOLDER: {
          LNAME: 'Enter last name',
          FNAME: 'Enter first name',
          REG_NUMBER: 'Enter registration number',
          DOB: 'Select date of birth',
          SCHOOL: 'Select school',
          CLASS: 'Select class',
          PARENT_PHONE_NUMBER: 'Enter parent\'s phone number',
          TYPE_REG_NUMBER: 'Select type of registration number',
        },
      },
      TAB_REDOUDLANT: {
        LABEL: {
          TITLE: 'Register a repeating student',
          REDOUBLANT: 'Repeating student',
        },
        TOOLTIP: {
          REDOUBLANT: 'Register a repeating student',
        },
        MESSAGE_REQUIRED: {
          LNAME: 'Last name is required',
          FNAME: 'First name is required',
          REG_NUMBER: 'Registration number is required',
          DOB: 'Date of birth is required',
          SCHOOL: 'School is required',
          CLASS: 'Class is required',
          PARENT_PHONE_NUMBER: 'Parent\'s phone number is required',
          TYPE_REG_NUMBER: 'Type of registration number is required',
        },
        PLACEHOLDER: {
          LNAME: 'Enter last name',
          FNAME: 'Enter first name',
          REG_NUMBER: 'Enter registration number',
          DOB: 'Select date of birth',
          SCHOOL: 'Select school',
          CLASS: 'Select class',
          PARENT_PHONE_NUMBER: 'Enter parent\'s phone number',
          TYPE_REG_NUMBER: 'Select type of registration number',
        },
      },
      MESSAGE_REQUIRED: {
        SOURCE_CLASS: 'Source classroom is required',
        TARGET_CLASS: 'Target classroom is required',
        SOURCE_SCHOOL_YEAR: 'Source school year is required',
        TARGET_SCHOOL_YEAR: 'Target school year is required'
      },
      SOURCE_CLASS: 'Source classroom',
      TARGET_CLASS: 'Target classroom',
      SOURCE_SCHOOL_YEAR: 'Source school year',
      TARGET_SCHOOL_YEAR: 'Target school year',
      TOGGLE_SELECT_ALL_STUDENT_TITLE: 'load all students',
      TOGGLE_UNSELECT_ALL_STUDENT_TITLE: 'search and load students',
    }


  },
  DASHBOARD: {
    TITLE: 'Dashboard: ',
    GREETING: 'Hi, Welcome',
    TOTAL_STUDENT: 'Total students ',
    TOTAL_PARENT: 'Total parents',
    TOTAL_CLASSE: 'Total classes',
    TOTAL_RETARD: 'Total delays',
  },
  GRADE: {
    DEVELOPER: 'Developer',
    TEACHER: 'Teacher',
    SCHOOL_ADMIN: 'School. Admin',
    PARENT: 'Parent',
    STUDENT: 'Student',
    STAFF: 'Staff'
  },
  SIDEBAR: {
    TITLE: 'Home',
    STUDENT_MANAGEMENT: {
      TITLE: 'Student management',
      INSCRIPTION: 'Inscriptions',
      STUDENT: 'Students',
      DELAY: 'Delays',
      ABSENCE: 'Absence'
    },
    NOTE_MANAGEMENT: {
      TITLE: 'Note management',
      NOTE: 'Notes',
      RESULT: 'Results'
    },
    COMMUNICATION_MANAGEMENT: {
      TITLE: 'Communication',
      NEWS: 'News',
      EVENT: 'Events'
    },
    CONFIGURATION: {
      TITLE: 'Settings',
      ADMIN: 'Administration',
      CONSOLE: 'Console',
      BASE_PARAMS: 'Config',
    }
  },
  PAGINATOR: {
    ITEMS_PER_PAGE: 'Items per page',
    NEXT_PAGE: 'Next page',
    PREVIOUS_PAGE: 'Previous page',
    FIRST_PAGE: 'First page',
    LAST_PAGE: 'Last page',
    ON: 'on'
  },
  CARD: {
    GRAPH_STAT: {
      DEFAULT_TITLE: 'Graphic',
      SCHOOL_TITLE: 'Schools Graphic',
      SCHOOL_WORK_FORCE_TITLE: 'Total workforce',
    },

    CLASSE_TITLE: 'Class',
    SCHOOL_TITLE: 'School',
    SCHOOL_YEAR_TITLE: 'School Year',
    MANAGE_ACTIVE_STUDENT_TITLE: 'Managing activated students',
    MANAGE_SIMPLE_TITLE: 'Default simple title',
    MANAGE_DESACTIVE_STUDENT_TITLE: 'Managing deactivated students',
    MANAGE_ACTIVE_INSCRIPTION_TITLE: 'Managing activated inscriptions',
    MANAGE_DESACTIVE_INSCRIPTION_TITLE: 'Managing deactivated inscriptions',
    TOGGLE_ACTIVE_TITLE: 'Show activated items',
    TOGGLE_DESACTIVE_TITLE: 'Show deactivated items',
    TOGGLE_CRITERIA_VALUE_TITLE: 'Clean up search criteria',
  },
  PLACEHOLDER: {
    SELECT_CLASSE: 'Select a class',
    SELECT_SCHOOL_YEAR: 'Select a school Year',
    SELECT_SCHOOL: 'Select a school'
  },
  MESSAGE: {
    EMPTY_DATA_SOURCE: 'No results found',
    SELECT_CLASSROOM: 'Select the classroom if available',
    LNAME_REQUIRED: 'Last name is required',
    FNAME_REQUIRED: 'First name is required',
    REGISTRATION_TYPE_REQUIRED: 'Registration type is required',
    REGISTRATION_NUMBER_REQUIRED: 'Registration number is required',
    SCHOOL_REQUIRED: 'School is required',
    CLASSE_REQUIRED: 'Class is required',
    DOB_REQUIRED: 'Date of birth is required',
    PAYMENT_STATUS_REQUIRED: 'Payment status is required',
    PAID_AMOUNT_REQUIRED: 'Min tuition fees: 0',
    SCHOOL_FEES_EXECED_AMOUNT: 'Do not exceed the outstanding balance:',
    SCHOOL_FEES_INVALID_AMOUNT: 'is an invalid amount.'
  },
  LABEL: {
    STUDENT: {
      LNAME: 'Last Name',
      FNAME: 'First Name',
      LNAME_FNAME: 'Last & First name',
      REG_TYPE: 'Registration Type',
      REG_NUMBER: 'Registration Number',
      TUTOR_PHONE: 'Parent Phone Number',
      DOB: 'Date of Birth',
      SCHOOL: 'School',
      AUTO_REG_NUMBER: 'Automatic Registration',
      MANUAL_REG_NUMBER: 'Manual Registration',
      FORMER_CLASSROOM: 'Former classroom',
      NEW_CLASSROOM: 'New classroom',
      STUDENT_CLASSROOM_STATUS: 'student status',
      LOAD_DATA_FROM: 'Load data from',
      SELECT_STUDENT: 'Select a student',
    },
    DELAY: {
      IS_DELAY: 'is late',
      IS_NOT_DELAY: 'not late'
    },
    FRANCO: 'Francophone',
    ANGLO: 'Anglophone',
    TRIMESTER1: 'Trimester 1',
    TRIMESTER2: 'Trimester 2',
    TRIMESTER3: 'Trimester 3',
    SEQUENCE1: 'Sequence 1',
    SEQUENCE2: 'Sequence 2',
    SEQUENCE3: 'Sequence 3',
    SEQUENCE4: 'Sequence 4',
    SEQUENCE5: 'Sequence 5',
    SEQUENCE6: 'Sequence 6',
    IS_INITIALIZED: 'Is Initialized',
    IS_NOT_INITIALIZED: 'Not Initialized',
    IS_EXAM_CLASSROOM: 'Exam classroom',
    IS_NOT_EXAM_CLASSROOM: 'Normal classroom',
    ENSEIGNANT: 'Teacher',
    STAFF: 'Staff',
    IS_SCHOOL_ADMIN: 'Is school admin',
    IS_NOT_SCHOOL_ADMIN: 'Is not school admin',
    IS_CURRENT: 'Is Current',
    IS_NOT_CURRENT: 'Not Current',
    IS_CHECK_IN: 'Checked In',
    IS_CHECK_OUT: 'Checked Out',
    BOTH_ARE_CHECK: 'Both Checked In and Out',
    CLASSE: 'Class',
    PAYMENT_STATUS: 'Payment status',
    PAID_AMOUNT: 'Payment of',
    ALREADY_PAID: 'Fees already paid'

  },
  DATASOURCE: {
    STUDENT: {
      AVATAR: 'Avatar',
      LAST_NAME: 'Last Name',
      FIRST_NAME: 'First Name',
      PARENT_PHONE_NUMBER: 'Tutor Phone Number',
      REG_NUMBER: 'Registration Number',
      UID: 'UID',
      PAYMENT: 'Payment',
      ACTION: 'Actions'
    }
  },
  SPINNER: {
    LOADING: {
      MESSAGE: 'Loading data...', // Message de chargement
      ICON: '⏳', // Hourglass icon
    },
    EMPTY: {
      MESSAGE: 'No data available', // Message pour données vides
      ICON: '📭', // Boîte vide
    },
    FAILED: {
      MESSAGE: 'Data not loaded', // Message d'échec
      ICON: '❌', // Cross icon
    },
    SUCCESS: {
      MESSAGE: 'Data loaded successfully!', // Message de succès
      ICON: '✅', // Checkmark icon
    },
  },
  ERROR_PAGE: {
    ERROR_401: {
      TITLE: 'Unauthorized Access',
      DESCRIPTION: 'You are not authorized to access this resource. Please log in.',
      INSTRUCTION: 'Log In',
      HELP: 'Need help?',
      SIGN_IN: 'Sign In',
    },
    ERROR_404: {
      TITLE: 'Page Not Found',
      DESCRIPTION: 'The page you are looking for does not exist. Check the URL or return to the homepage.',
      INSTRUCTION: 'Return to Home',
      HELP: 'Need help?',
      SIGN_IN: 'Sign In',
    },
    ERROR_500: {
      TITLE: 'Looks like you are lost',
      DESCRIPTION: 'Oops, something went wrong. Please try again in a few moments.',
      INSTRUCTION: 'Go to Dashboard',
      HELP: 'Need help?',
      SIGN_IN: 'Sign In',
    },
    ERROR_403: {
      TITLE: 'Forbidden Access',
      DESCRIPTION: 'You do not have the necessary permissions to access this resource.',
      INSTRUCTION: 'Go back to the previous page',
      HELP: 'Need help?',
      SIGN_IN: 'Sign In',
    },
    ERROR_408: {
      TITLE: 'Request Timeout',
      DESCRIPTION: 'The request took too long. Please try again.',
      INSTRUCTION: 'Retry',
      HELP: 'Need help?',
      SIGN_IN: 'Sign In',
    },
    ERROR_502: {
      TITLE: 'Bad Gateway',
      DESCRIPTION: 'The gateway server received an invalid response from another server.',
      INSTRUCTION: 'Retry',
      HELP: 'Need help?',
      SIGN_IN: 'Sign In',
    },
    ERROR_503: {
      TITLE: 'Service Unavailable',
      DESCRIPTION: 'The service is temporarily offline. Please try again later.',
      INSTRUCTION: 'Retry later',
      HELP: 'Need help?',
      SIGN_IN: 'Sign In',
    },
    ERROR_504: {
      TITLE: 'Gateway Timeout',
      DESCRIPTION: 'The server did not respond within the allotted time.',
      INSTRUCTION: 'Retry',
      HELP: 'Need help?',
      SIGN_IN: 'Sign In',
    },
  },
  CONSOLE: {
    ICON: {
      EDIT: 'edit',
      DELETE: 'delete',
      INIT_AT: 'initialized',
      ADD_SECTION: 'add section',
      ADD_LEVEL: 'initialise levels',
      ADD_CLASS: 'clone classroom',
      ASSIGN_CLASS: 'assign classroom',
    },
    HEADER: {
      TAB_ONE_TITLE: 'Basic conf.',
      TAB_TWO_TITLE: 'Medium conf.',
      TAB_THREE_TITLE: 'High conf.',
      TAB_FOUR_TITLE: 'Complex conf.',
    },
    CARD: {
      NEWS_TITLE: 'Message',
      EVENT_TITLE: 'Events',
      NOTE_TITLE: 'Notes',
      RESULT_TITLE: 'Results',
      DELAY_TITLE: 'Delays',
      STUDENT_TITLE: 'Students',
      DETAIL_TITLE: 'Details',
      INSCRIPTION_TITLE: 'Registrations',
      ABSENCE_TITLE: 'Absences',
      SECTION_TITLE: 'Sections',
      SCHOOL_TITLE: 'Schools',
      SCHOOL_YEAR_TITLE: 'School Years',
      PROFIL_TITLE: 'Profiles',
      TRIMESTRE_TITLE: 'Trimesters',
      LEVEL_TITLE: 'Levels',
      OPTION_TITLE: 'Options',
      EVALUATION_TITLE: 'Evaluations',
      CLASSE_TITLE: 'Classes',
      COURSE_TITLE: 'Subjects',
      GROUP_TITLE: 'Groups',
      STAFF_TITLE: 'Technical Team',
      SEQUENCE_TITLE: 'Sequences',
      TEACHER_TITLE: 'Teachers',
      TUTOR_TITLE: 'Parents',
      VERIFICATION_TITLE: 'Verification',
      PROGRAM_TITLE: 'Programs',
      PLANNING_TITLE: 'Timetables',
      PLAGE_TITLE: 'Time Slots',
    }
    ,
    DIALOG: {
      CLASS: {
        TITLE: {
          ADD_TITLE: 'Add a class',
          EDIT_TITLE: 'Edit this class',
          DELETE_TITLE: 'Delete this class'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'Title is required',
          CODE: 'Code is required',
          SCHOOL: 'School is required',
          HARDWAREID: 'Hardware ID is required',
          LEVEL: 'Level is required',
          OPTION: 'Option is required',
          CLASSROOM_TYPE: 'Classroom type is required',
        },
        PLACEHOLDER: {
          INTITULE: 'e.g. class name',
          CODE: 'Code',
          HARDWAREID: 'Hardware ID'
        },
        CHOOSE_CLASS: 'Select the class',
        INTITULE: 'Designation',
        HARDWAREID: 'Hardware ID',
        CODE: 'Code',
        LEVEL: 'Level',
        OPTION: 'Option',
        SCHOOL: 'School',
        INITIALIZE_COURSE: 'Initialized course status',
        CLASSROOM_TYPE: 'Classroom type',
        REGISTRE: 'Register'
      },

      SECTION: {
        TITLE: {
          ADD_TITLE: 'Add a section',
          EDIT_TITLE: 'Edit this section',
          DELETE_TITLE: 'Delete this section'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'Title is required',
          CODE: 'Code is required',
          SCHOOL: 'School is required',
          REGISTRE: 'Register is required',
        },
        PLACEHOLDER: {
          INTITULE: 'e.g. section name',
          CODE: 'Code'
        },
        CHOOSE_SECTION: 'Select section',
        INTITULE: 'Title',
        CODE: 'Code',
        SCHOOL: 'School',
        INITIALIZE_COURSE: 'Initialized courses status',
        REGISTRE: 'Register'
      },
      GROUP: {
        TITLE: {
          ADD_TITLE: 'Add a group',
          EDIT_TITLE: 'Edit this group',
          DELETE_TITLE: 'Delete this group'
        },
        MESSAGE_REQUIRED: {
          TITLE: 'Title is required',
          CODE: 'Code is required'
        },
        PLACEHOLDER: {
          INTITULE: 'e.g. group name',
          CODE: 'Code'
        },
        CHOOSE_GROUP: 'Choose group',
        INTITULE: 'Title',
        CODE: 'Code',
      },
      QUATER: {
        TITLE: {
          ADD_TITLE: 'Add a quarter',
          EDIT_TITLE: 'Edit this quarter',
          DELETE_TITLE: 'Delete this quarter'
        },
        MESSAGE_REQUIRED: {
          TITLE: 'Title is required',
          CODE: 'Code is required'
        },
        PLACEHOLDER: {
          INTITULE: 'e.g. quarter name',
          CODE: 'Code'
        },
        CHOOSE_GROUP: 'Choose group',
        INTITULE: 'Title',
        CODE: 'Code',
      },
      PROFIL: {
        TITLE: {
          ADD_TITLE: 'Add a profil',
          EDIT_TITLE: 'Edit this profil',
          DELETE_TITLE: 'Delete this profil'
        },
        MESSAGE_REQUIRED: {
          TITLE: 'Title is required',
          INITIALIZED: 'is required'
        },
        PLACEHOLDER: {
          INTITULE: 'e.g. profil name',
          INITIALIZED: 'Is initialized'
        },
        INTITULE: 'Title',
        INITIALIZED: 'Is initialized',
      },

      LEVEL: {
        TITLE: {
          ADD_TITLE: 'Add a level',
          EDIT_TITLE: 'Edit this level',
          DELETE_TITLE: 'Delete this level'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'Title is required',
          CODE: 'Code is required',
          SECTION: 'Section is required',
        },
        PLACEHOLDER: {
          INTITULE: 'e.g. level name',
          CODE: 'Code'
        },
        CHOOSE_SECTION: 'Select section',
        INTITULE: 'Title',
        CODE: 'Code',
        SECTION: 'Section'
      },
      SCHOOL: {
        TITLE: {
          ADD_TITLE: 'Add a school',
          EDIT_TITLE: 'Edit this school',
          DELETE_TITLE: 'Delete this school',
          INIT_COURSE_TITLE: 'initialized courses',
          CLONE_CLASS_TITLE: 'Clone class',
        },
        MESSAGE_REQUIRED: {
          SECTION: 'Section type is required',
          SCHOOL_TYPE: 'School Type is required',
          ADRESS: 'Adress is required',
          PO_BOX: 'PO Box is required',
          PICTURE: 'Picture is required',
          SERVER_LINK: ' Server link is required',
          URL: 'Url is required',
          DEFAULT: 'Is  Default Type is required',
          INTITULE: 'School name is required',
          SOURCE_SCHOOL: 'School name is required',
          SOURCE_SECTION: 'Source section is required',
          TARGET_SECTION: 'Target section is required',
        },
        PLACEHOLDER: {
          ADRESS: 'e.g. adress',
          PO_BOX: 'e.g. PO Box',
          PICTURE: 'e.g. picture',
          SERVER_LINK: 'e.g. www.domaine.com',
          URL: 'e.g. url',
          INTITULE: 'e.g. school name',
          CHOOSE_SCHOOL: 'School'
        },
        INTITULE: 'School name',
        DEFAULT: 'Default type',
        URL: 'Url',
        PO_BOX: 'PO Box',
        PICTURE: 'Picture',
        SERVER_LINK: 'Http link',
        ADRESS: 'Adress',
        SECTION: 'Section',
        SCHOOL_TYPE: 'School type',
        IS_COURSE_INITIALIZE: 'are Initialized',
        IS_NOT_COURSE_INITIALIZE: 'not Initialized',
        NO_SECTION_AVAILABLE: 'No sections available.',
        CREATE_SECTION_PROMPT: 'You can create new sections in section box.',
        SOURCE_SCHOOL: 'Source school',
        TARGET_SCHOOL: 'Target school',
        SOURCE_SECTION: 'Source section',
        TARGET_SECTION: 'Target section'
      },
      SCHOOL_YEAR: {
        TITLE: {
          ADD_TITLE: 'Add a school year',
          EDIT_TITLE: 'Edit this school year',
          DELETE_TITLE: 'Delete this school year'
        },
        MESSAGE_REQUIRED: {
          FROM_YEAR: 'Start year is required',
          TO_YEAR: 'End year is required',
          CURRENT_YEAR: 'Year type is required',
          SOURCE_SCHOOL_YEAR: 'Source school is required',
        },
        PLACEHOLDER: {
          FROM_YEAR: 'e.g. YYYY',
          TO_YEAR: 'e.g. YYYY',
        },
        FROM_YEAR: 'Start year',
        TO_YEAR: 'End year',
        START_DATE: 'Start date',
        END_DATE: 'End date',
        CURRENT_YEAR: 'This year is',
        CHOOSE_SCHOOL_YEAR: 'School year',
        INTITULE: 'School year',
        SOURCE_SCHOOL_YEAR: 'Source school year',
        TARGET_SCHOOL_YEAR: 'Target school year'
      },
      SEQUENCE: {
        TITLE: {
          ADD_TITLE: 'Add a sequence',
          EDIT_TITLE: 'Edit this sequence',
          DELETE_TITLE: 'Delete this sequence'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'Title is required',
          CODE: 'Code is required',
          QUARTER: 'Quarter is required'
        },
        PLACEHOLDER: {
          INTITULE: 'e.g. sequence name',
          CODE: 'Code'
        },
        CHOOSE_SEQUENCE: 'Choose sequence',
        INTITULE: 'Title',
        CODE: 'Code',
        QUARTER: 'Quarter'
      },
      OPTION: {
        TITLE: {
          ADD_TITLE: 'Add an option',
          EDIT_TITLE: 'Edit this option',
          DELETE_TITLE: 'Delete this option'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'Title is required',
          SECTION: 'Section is required',
          LEVEL: 'Level is required'
        },
        PLACEHOLDER: {
          INTITULE: 'e.g. option name'
        },
        CHOOSE_OPTION: 'Choose option',
        INTITULE: 'Title',
        SECTION: 'Section',
        LEVEL: 'Level'
      },
      COURSE: {
        TITLE: {
          ADD_TITLE: 'Add a subject',
          EDIT_TITLE: 'Edit this subject',
          DELETE_TITLE: 'Delete this subject'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'The title is required',
          CODE: 'The code is required',
          GROUP: 'The group is required',
          INITIALIZED: 'is required'
        },
        PLACEHOLDER: {
          INTITULE: 'e.g., level name',
          CODE: 'Code'
        },
        CHOOSE_COURSE: 'Select the subject',
        INTITULE: 'Title',
        CODE: 'Code',
        GROUP: 'Group',
        INITIALIZED: 'the subject is'
      },
      TEACHER: {
        TITLE: {
          ADD_TITLE: 'Add a teacher',
          RENEW_TITLE: 'Teacher Renewal',
          EDIT_TITLE: 'Edit this teacher',
          DELETE_TITLE: 'Delete teacher',
          ASSIGN_CLASS_TITLE: 'Classroom assignment',
        },
        MESSAGE_REQUIRED: {
          IS_SCHOOL_ADMIN: 'The value is required',
          FNAME: 'The first name is required',
          LNAME: 'The last name is required',
          PHONE_NUMBER: 'The Phone number is required',
          REG_NUMBER: 'Registration number is required',
        },
        PLACEHOLDER: {
          FNAME: 'e.g John',
          LNAME: 'e.g Doe',
          PHONE_NUMBER: 'e.g 00000000',
          REG_NUMBER: 'ex. XQSUI',
        },
        FNAME: 'First name',
        LNAME: 'Last name',
        PHONE_NUMBER: 'Phone number',
        IS_SCHOOL_ADMIN: 'School Admin',
        REG_NUMBER: 'Reg. Number',
        REG_NUMBER_AUTO: 'Automatic Reg. Number ',
        REG_NUMBER_MAN: 'Manuel Reg. Number',
      },
      PLANNING: {
        TITLE: {
          ADD_UPDATE_TITLE: 'Add or update the schedule',
          DELETE_TITLE: 'Delete schedule',
        },
      },


    },
    MENU: {
      DOWNLOAD: 'Download',
      REFRESH: 'Refresh',
      ADD: 'Add',
      EDIT: 'Edit',
      DELETE: 'Delete',
      SEARCH: 'Search',
      ASSOCIATE: 'Associate',
      ORDER: 'Order',
      Clone: 'Clone',
      RENEW: 'Renew',
    },
    MESSAGE: {
      EMPTY_DATA: ' is empty!',
      EMPTY_SCHOOL_DATA: 'The school content is empty.',
    }
  },
  CONFIG: {
    CARD: {
      TITLE: 'General School config',
      BLOC: {
        BLOC1: {
          TITLE: 'Bloc 1 titre ici'
        }
      }
    },
    DIALOG: {
      CLASS: {
        TITLE: {
          ADD_TITLE: 'Ajouter une classe',
          EDIT_TITLE: 'Modifier cette classe',
          DELETE_TITLE: 'Supprimer cette classe'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'L\'intitulé est requis',
          CODE: 'Le code est requis',
          SCHOOL: 'L\'établissement est requis',
          HARDWAREID: 'Hardware ID  requis',
          LEVEL: 'Niveau  requis',
          OPTION: 'Option  requis',
          CLASSROOM_TYPE: 'Type de classe  requis',
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom classe',
          CODE: 'Code',
          HARDWAREID: 'Hardware ID'
        },
        CHOOSE_CLASS: 'Selectionner la classe',
        INTITULE: 'Désignation',
        HARDWAREID: 'Hardware ID',
        CODE: 'Code',
        LEVEL: 'Niveau',
        OPTION: 'Option',
        SCHOOL: 'Etablissement',
        INITIALIZE_COURSE: 'Statut des cours initialisés',
        CLASSROOM_TYPE: 'Type de classe',
        REGISTRE: 'Registre'
      },
      SECTION: {
        TITLE: {
          ADD_TITLE: 'Ajouter une section',
          EDIT_TITLE: 'Modifier cette section',
          DELETE_TITLE: 'Supprimer cette section'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'L\'intitulé est requis',
          CODE: 'Le code est requis',
          SCHOOL: 'L\'établissement est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom section',
          CODE: 'Code'
        },
        CHOOSE_SECTION: 'Selectionner section',
        INTITULE: 'Désignation',
        CODE: 'Code',
        SCHOOL: 'Etablissement',
        INITIALIZE_COURSE: 'Statut des cours initialisés',
        REGISTRE: 'Registre'
      },
      GROUP: {
        TITLE: {
          ADD_TITLE: 'Ajouter un group',
          EDIT_TITLE: 'Modifier ce group',
          DELETE_TITLE: 'Supprimer ce group'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'L\'intitulé est requis',
          CODE: 'Le code est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom group',
          CODE: 'Code'
        },
        CHOOSE_GROUP: 'Selectionner group',
        INTITULE: 'Désignation',
        CODE: 'Code',
      },
      QUATER: {
        TITLE: {
          ADD_TITLE: 'Ajouter un trimestre',
          EDIT_TITLE: 'Modifier ce trimestre',
          DELETE_TITLE: 'Supprimer ce trimestre'
        },
        MESSAGE_REQUIRED: {
          TITLE: 'Le titre est requis',
          CODE: 'Le code est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex. nom du trimestre',
          CODE: 'Code'
        },
        CHOOSE_GROUP: 'Choisir le groupe',
        INTITULE: 'Titre',
        CODE: 'Code',
      },
      PROFIL: {
        TITLE: {
          ADD_TITLE: 'Ajouter un profil',
          EDIT_TITLE: 'Modifier ce profil',
          DELETE_TITLE: 'Supprimer ce profil'
        },
        MESSAGE_REQUIRED: {
          TITLE: 'Le titre est requis',
          INITIALIZED: 'est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex. nom du profil',
          INITIALIZED: 'Est initialisé'
        },
        INTITULE: 'Titre',
        INITIALIZED: 'Est initialisé',
      },
      LEVEL: {
        TITLE: {
          ADD_TITLE: 'Ajouter un niveau',
          EDIT_TITLE: 'Modifier ce niveau',
          DELETE_TITLE: 'Supprimer ce niveau'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'Le titre est requis',
          CODE: 'Le code est requis',
          SECTION: 'La section est requise',
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom du niveau',
          CODE: 'Code'
        },
        CHOOSE_SECTION: 'Sélectionner la section',
        INTITULE: 'Titre',
        CODE: 'Code',
        SECTION: 'Section'
      },
      SCHOOL: {
        TITLE: {
          ADD_TITLE: 'Ajouter un établissement',
          EDIT_TITLE: 'Modifier cet établissement',
          DELETE_TITLE: 'Supprimer cet établissement',
          INIT_COURSE_TITLE: 'Initialisation des cours',
          CLONE_CLASS_TITLE: 'Clonage de classe',
        },
        MESSAGE_REQUIRED: {
          SECTION: 'Le type de section est requis',
          SCHOOL_TYPE: 'Le type d\'établissement est requis',
          ADRESS: 'L\'adresse est requise',
          PO_BOX: 'La boite postale est requise',
          PICTURE: 'L\'image est requise',
          SERVER_LINK: 'Le lien du serveur est requis',
          URL: 'L\'URL est requise',
          DEFAULT: 'Le type par défaut est requis',
          INTITULE: 'L\'intitulé est requis',
          SOURCE_SCHOOL: 'Etablissement source requis',
          SOURCE_SECTION: 'Section souce requise',
          TARGET_SECTION: 'Section cible requise',
        },
        PLACEHOLDER: {
          ADRESS: 'ex: adresse',
          PO_BOX: 'ex: BP 123',
          PICTURE: 'ex: image',
          SERVER_LINK: 'ex: www.domaine.com',
          URL: 'ex: url',
          INTITULE: 'ex: nom établissement',
          CHOOSE_SCHOOL: 'Etablissement'
        },
        INTITULE: 'Désignation',
        DEFAULT: 'Type par défaut',
        URL: 'URL',
        PO_BOX: 'Boite postal',
        PICTURE: 'Image',
        SERVER_LINK: 'Lien HTTP',
        ADRESS: 'Adresse',
        SECTION: 'Section',
        SCHOOL_TYPE: 'Type d\'établissement',
        IS_COURSE_INITIALIZE: 'sont initialisés',
        IS_NOT_COURSE_INITIALIZE: 'pas initialisés',
        NO_SECTION_AVAILABLE: 'Aucune section disponible.',
        CREATE_SECTION_PROMPT: 'Vous pouvez créer de nouvelles sections dans le box Section.',
        SOURCE_SCHOOL: 'Etablissement source',
        TARGET_SCHOOL: 'Etablissement cible',
        SOURCE_SECTION: 'Section source',
        TARGET_SECTION: 'Section cible'
      },
      SCHOOL_YEAR: {
        TITLE: {
          ADD_TITLE: 'Ajouter une année scolaire',
          EDIT_TITLE: 'Modifier cet année scolaire',
          DELETE_TITLE: 'Supprimer cet année scolaire'
        },
        MESSAGE_REQUIRED: {
          FROM_YEAR: 'Le début d\'année est requis',
          TO_YEAR: 'La fin d\'année  est requise',
          CURRENT_YEAR: 'Le type d\'année est requis',
          SOURCE_SCHOOL_YEAR: 'Etablissement source requis',
        },
        PLACEHOLDER: {
          FROM_YEAR: 'ex: YYYY',
          TO_YEAR: 'ex: YYYY',
        },
        FROM_YEAR: 'Début d\'année',
        TO_YEAR: 'Fin d\'année',
        START_DATE: 'Date debut',
        END_DATE: 'Date fin',
        CURRENT_YEAR: 'Cette année est',
        CHOOSE_SCHOOL_YEAR: 'Année scolaire',
        INTITULE: 'Année scolaire',
        SOURCE_SCHOOL_YEAR: 'Année scolaire source',
        TARGET_SCHOOL_YEAR: 'Année scolaire cible',
      },
      SEQUENCE: {
        TITLE: {
          ADD_TITLE: 'Ajouter une sequence',
          EDIT_TITLE: 'Modifier cette sequence',
          DELETE_TITLE: 'Supprimer cette sequence'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'L\'intitulé est requis',
          CODE: 'Le code est requis',
          QUARTER: 'Le trimestre est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom sequence',
          CODE: 'Code'
        },
        CHOOSE_SEQUENCE: 'Selectionner sequence',
        INTITULE: 'Désignation',
        CODE: 'Code',
        QUARTER: 'Trimestre'
      },
      OPTION: {
        TITLE: {
          ADD_TITLE: 'Ajouter une option',
          EDIT_TITLE: 'Modifier cette option',
          DELETE_TITLE: 'Supprimer cette option'
        },
        MESSAGE_REQUIRED: {
          INTITULE: "L\'intitulé est requis",
          SECTION: 'La section est requise',
          LEVEL: 'Le niveau est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom option',
        },
        CHOOSE_OPTION: 'Selectionner sequence',
        INTITULE: 'Désignation',
        SECTION: 'Section',
        QUARTER: 'Level'
      },
      COURSE: {
        TITLE: {
          ADD_TITLE: 'Ajouter une matière',
          EDIT_TITLE: 'Modifier cette matière',
          DELETE_TITLE: 'Supprimer cette matière'
        },
        MESSAGE_REQUIRED: {
          INTITULE: 'Le titre est requis',
          CODE: 'Le code est requis',
          GROUP: 'Le group est requis',
          INITIALIZED: 'est requis'
        },
        PLACEHOLDER: {
          INTITULE: 'ex: nom du niveau',
          CODE: 'Code'
        },
        CHOOSE_COURSE: 'Sélectionner la matière',
        INTITULE: 'Titre',
        CODE: 'Code',
        GROUP: 'Groupe',
        INITIALIZED: 'la matiere est'
      },
      TEACHER: {
        TITLE: {
          ADD_TITLE: 'Ajouter un enseignant',
          RENEW_TITLE: 'Renouvèlement des enseignants',
          EDIT_TITLE: 'Modifier cet enseignant',
          DELETE_TITLE: 'Supprimer l\'enseignant',
          ASSIGN_CLASS_TITLE: 'Affectation de classe',
        },
        MESSAGE_REQUIRED: {
          IS_SCHOOL_ADMIN: 'Valeur requise',
          FNAME: 'Prénom requis',
          LNAME: 'Nom requis',
          PHONE_NUMBER: 'Numéro de téléphone requis',
          REG_NUMBER: 'Registration number is required',
        },
        PLACEHOLDER: {
          FNAME: 'ex. John',
          LNAME: 'ex. Doe',
          PHONE_NUMBER: 'ex. 00000000',
          REG_NUMBER: 'ex. XQSUI',
        },
        FNAME: 'Prénom',
        LNAME: 'Nom',
        PHONE_NUMBER: 'Téléphone',
        IS_SCHOOL_ADMIN: 'Administrateur école',
        REG_NUMBER: 'Matricule',
        REG_NUMBER_AUTO: 'Matricule automatique',
        REG_NUMBER_MAN: 'Matricule manuel',
      },
      PLANNING: {
        TITLE: {
          ADD_UPDATE_TITLE: 'Ajouter ou modifier le planning',
          DELETE_TITLE: 'Supprimer planning',
        },
      },

    },
    MENU: {
      DOWNLOAD: 'Télécharger',
      REFRESH: 'Actualiser',
      ADD: 'Ajouter',
      EDIT: 'Editer',
      DELETE: 'Supprimer',
      SEARCH: 'Rechercher',
      ASSOCIATE: 'Associer',
      ORDER: 'Ordonner',
      Clone: 'Cloner',
      RENEW: 'Renouveler',
    },
    MESSAGE: {
      EMPTY_DATA: ' vide!',
      EMPTY_SCHOOL_DATA: 'Le contenu de l\'école est vide.',
    }
  },


  // Ajoutez d'autres sections et constantes au besoin
};
