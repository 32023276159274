import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Effacer toutes les données du localStorage sauf 'appLanguage'
          Object.keys(localStorage)
            .forEach(key => {
              if (key !== 'appLanguage') {
                localStorage.removeItem(key);
              }
            });
          // Rediriger vers la page 404
          this.router.navigate(['/error/401']);
        } else if (error.status === 404) {
          // Rediriger vers la page 404
          this.router.navigate(['/error/404']);
        } else if (error.status === 500) {
          // Rediriger vers la page 500
          this.router.navigate(['/error/500']);
        }
        return throwError(() => error);
      })
    );
  }
}
