import {MatDateFormats, NativeDateAdapter} from '@angular/material/core';
import {DateFormatEnum} from "../enums/date-format-enum";
import {environment} from "../../../environments/environment";

export class CustomDateAdapter extends NativeDateAdapter {
  private currentFormat: DateFormatEnum = environment.dateFormat;

  setFormat(format: DateFormatEnum) {
    this.currentFormat = format;
  }

  override format(date: Date, displayFormat: string): string {
    if (!date) return '';

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const monthShort = this.getMonthNames('short')[date.getMonth()]; // Ex: Jan, Feb, Mar...
    const monthLong = this.getMonthNames('long')[date.getMonth()]; // Ex: January, February...


    switch (this.currentFormat) {
      case DateFormatEnum.DD_MM_YYYY:
        return `${day}/${month}/${year}`;
      case DateFormatEnum.MM_YYYY:
        return `${month}-${year}`;
      case DateFormatEnum.MMM_YYYY:
        return `${monthShort} ${year}`; // Format ajouté
      case DateFormatEnum.MMMM_YYYY:
        return `${monthLong} ${year}`;
      default:
        return `${year}-${month}-${day}`; // yyyy-MM-dd par défaut
    }
  }
}

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'yyyy-MM-dd',
  },
  display: {
    dateInput: 'yyyy-MM-dd',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
