import {RouteInfo} from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: '-- Main',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: '/dashboard/main',
    title: 'SIDEBAR.TITLE',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: '',
    title: 'SIDEBAR.STUDENT_MANAGEMENT.TITLE',
    iconType: 'material-icons-two-tone',
    icon: 'account_circle',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/gestion-eleves/inscription',
        title: 'SIDEBAR.STUDENT_MANAGEMENT.INSCRIPTION',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/gestion-eleves/eleve',
        title: 'SIDEBAR.STUDENT_MANAGEMENT.STUDENT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/gestion-eleves/retard',
        title: 'SIDEBAR.STUDENT_MANAGEMENT.DELAY',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/gestion-eleves/absence',
        title: 'SIDEBAR.STUDENT_MANAGEMENT.ABSENCE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'SIDEBAR.NOTE_MANAGEMENT.TITLE',
    iconType: 'material-icons-two-tone',
    icon: 'assessment',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/gestion-notes/note',
        title: 'SIDEBAR.NOTE_MANAGEMENT.NOTE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/gestion-notes/resultat',
        title: 'SIDEBAR.NOTE_MANAGEMENT.RESULT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'SIDEBAR.COMMUNICATION_MANAGEMENT.TITLE',
    iconType: 'material-icons-two-tone',
    icon: 'class',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/gestion-com/nouvelle',
        title: 'SIDEBAR.COMMUNICATION_MANAGEMENT.NEWS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/gestion-com/evenement',
        title: 'SIDEBAR.COMMUNICATION_MANAGEMENT.EVENT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      }
    ],
  },
];

export const ADMIN_ROUTES: RouteInfo[] = [
  {
    path: '',
    title: '-- Main',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: '/dashboard/main',
    title: 'SIDEBAR.TITLE',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: '',
    title: 'SIDEBAR.STUDENT_MANAGEMENT.TITLE',
    iconType: 'material-icons-two-tone',
    icon: 'account_circle',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/gestion-eleves/inscription',
        title: 'SIDEBAR.STUDENT_MANAGEMENT.INSCRIPTION',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/gestion-eleves/eleve',
        title: 'SIDEBAR.STUDENT_MANAGEMENT.STUDENT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/gestion-eleves/retard',
        title: 'SIDEBAR.STUDENT_MANAGEMENT.DELAY',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/gestion-eleves/absence',
        title: 'SIDEBAR.STUDENT_MANAGEMENT.ABSENCE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'SIDEBAR.NOTE_MANAGEMENT.TITLE',
    iconType: 'material-icons-two-tone',
    icon: 'assessment',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/gestion-notes/note',
        title: 'SIDEBAR.NOTE_MANAGEMENT.NOTE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/gestion-notes/resultat',
        title: 'SIDEBAR.NOTE_MANAGEMENT.RESULT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'SIDEBAR.COMMUNICATION_MANAGEMENT.TITLE',
    iconType: 'material-icons-two-tone',
    icon: 'class',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/gestion-com/nouvelle',
        title: 'SIDEBAR.COMMUNICATION_MANAGEMENT.NEWS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/gestion-com/evenement',
        title: 'SIDEBAR.COMMUNICATION_MANAGEMENT.EVENT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'SIDEBAR.CONFIGURATION.TITLE',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: '',
    title: 'SIDEBAR.CONFIGURATION.ADMIN',
    iconType: 'material-icons-two-tone',
    icon: 'settings',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/gestion-admin/console',
        title: 'SIDEBAR.CONFIGURATION.CONSOLE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
    ],
  },
];
