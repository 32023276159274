import {NgModule} from '@angular/core';
import {AppTranslateService} from '../service/translate.service';
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {MaterialModule} from "./material.module";
import {FeatherIconsModule} from './components/feather-icons/feather-icons.module';

// @ts-ignore
@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, TranslateModule.forRoot()],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FeatherIconsModule,
  ],
  providers: [
    AppTranslateService,
    // { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
    // Ajoutez d'autres services si nécessaire
  ],
})
export class SharedModule {
}
