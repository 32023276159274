// /src/app/core/translations/enum-translations.type.ts
import { Civilite } from '../enums/civilite';

export const CiviliteTranslations = {
  [Civilite.Monsieur]: {
    en: 'Mister',
    fr: 'Monsieur',
    es: 'Señor',
  },
  [Civilite.Madame]: {
    en: 'Madam',
    fr: 'Madame',
    es: 'Señora',
  },
  [Civilite.Mademoiselle]: {
    en: 'Miss',
    fr: 'Mademoiselle',
    es: 'Señorita',
  },
  // Ajoutez d'autres traductions pour Civilite au besoin
} as const;
