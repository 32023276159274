export enum Timezone {
  UTC = "UTC",
  EUROPE_PARIS = "Europe/Paris",
  AFRICA_DOUALA = "Africa/Douala",
  INDIAN_MAURITIUS = "Indian/Mauritius"
}

type TimezoneInfo = {
  gmtOffset: string; // Décalage GMT
  usesDST?: boolean; // Utilise l'heure d'été (Daylight Saving Time)
};

const TimezoneGMT: Record<Timezone, TimezoneInfo> = {
  [Timezone.UTC]: {gmtOffset: "GMT+0"},
  [Timezone.EUROPE_PARIS]: {gmtOffset: "GMT+1", usesDST: true},
  [Timezone.AFRICA_DOUALA]: {gmtOffset: "GMT+1", usesDST: true},
  [Timezone.INDIAN_MAURITIUS]: {gmtOffset: "GMT+4", usesDST: true},
};

const userTimezone = Timezone.AFRICA_DOUALA;
console.log(`Fuseau horaire : ${userTimezone}, Décalage : ${TimezoneGMT[userTimezone].gmtOffset}`);
// Affiche : "Fuseau horaire : Europe/Paris, Décalage : GMT+1"
