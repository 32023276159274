// /src/app/core/constants/wording.es.ts
export const WordingEs = {
  LOGIN: {
    WELCOME_MESSAGE: 'Bienvenido a nuestra aplicación!',
    USERNAME: 'Nombre de usuario',
    PASSWORD: 'Contraseña',
    SIGN_IN: 'Iniciar sesión',
    FORGOT_PASSWORD: 'Olvidó su contraseña?',
  },
  SIGNUP: {
    CREATE_ACCOUNT: 'Crear cuenta',
    EMAIL: 'Correo electrónico',
    CONFIRM_PASSWORD: 'Confirmar contraseña',
    TERMS_CONDITIONS: 'Acepto los términos y condiciones',
    SIGN_UP: 'Registrarse',
  },
  FORGOT_PASSWORD: {
    RESET_PASSWORD: 'Restablecer contraseña',
    ENTER_EMAIL: 'Ingrese su dirección de correo electrónico',
    SEND_RESET_LINK: 'Enviar enlace de restablecimiento',
  },
  // Ajoutez d'autres sections et constantes au besoin
};
