import {Component} from '@angular/core';

@Component({
  selector: 'app-page500',
  templateUrl: './page500.component.html',
  styleUrls: ['./page500.component.scss'],
})
export class Page500Component {
  error = {
    code: '500',
    title: 'ERROR_PAGE.ERROR_500.TITLE',
    description: 'ERROR_PAGE.ERROR_500.DESCRIPTION',
    instruction: 'ERROR_PAGE.ERROR_500.INSTRUCTION',
    help: 'ERROR_PAGE.ERROR_500.HELP',
    signIn: 'ERROR_PAGE.ERROR_500.SIGN_IN',
    backgroundImage: '../../../../assets/images/background/bg.png'
  };
}
