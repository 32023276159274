import {Router, NavigationEnd} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from '@angular/core';
import {ROUTES, ADMIN_ROUTES} from './sidebar-items';
import {AuthService} from 'src/app/core/service/auth.service';
import {Subscription} from "rxjs";
import {User} from 'src/app/core/models/user';
import {RouteInfo} from "./sidebar.metadata";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems!: any[];
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight!: string;
  listMaxWidth!: string;
  userFullName!: string;
  userImg!: string;
  userType!: string;
  headerHeight = 60;
  routerObj = new Subscription();
  currentRoute!: string;
  currentUser!: User;
  currentUserGrade!: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router
  ) {
    const body = this.elementRef.nativeElement.closest('body');

    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  windowResizecall(event: any) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }

  callToggleMenu(event: any, length: any) {
    if (length > 0) {
      const parentElement = event.target.closest('li');
      const activeClass = parentElement.classList.contains('active');

      if (activeClass) {
        this.renderer.removeClass(parentElement, 'active');
      } else {
        this.renderer.addClass(parentElement, 'active');
      }
    }
  }

  ngOnInit() {
    if (this.authService.currentUserValue) {
      this.currentUser = this.authService.currentUserValue;
      this.currentUserGrade = this.authService.checkUserGrade();
      this.sidebarItems = this.sidebarByUserGrade();
    }
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }

  sidebarByUserGrade = (): RouteInfo[] => {
    const routesToFilter = this.currentUserGrade === 'GRADE.SCHOOL_ADMIN' || this.currentUserGrade === 'GRADE.STAFF'
      ? ADMIN_ROUTES
      : ROUTES;

    return routesToFilter.filter(sidebarItem => sidebarItem);
  }




  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }

  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }

  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }

  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }

  checkStatuForResize(firstTime: any) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }

  mouseHover(e: any) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }

  mouseOut(e: any) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }
}
