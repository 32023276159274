import {DOCUMENT} from '@angular/common';
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, Renderer2,} from '@angular/core';
import {Router} from '@angular/router';
import {catchError, combineLatest, of, tap, timer} from 'rxjs';
import {ConfigService} from 'src/app/config/config.service';
import {User} from 'src/app/core/models/user';
import {NotificationService} from 'src/app/core/notification.service';
import {AuthService} from 'src/app/core/service/auth.service';
import {RightSidebarService} from 'src/app/core/service/rightsidebar.service';
import {FormLabels} from '../../constants/globals';
import {AppTranslateService} from "../../core/service/translate.service";
import {LocalStorageService} from "../../core/service/local-storage-service";
import {AppError} from "../../core/types/app-error";
import {SchoolService} from "../../core/service/school.service";
import {School} from "../../core/models/school";
import {SchoolYear} from "../../core/models/school-year";
import {SchoolYearService} from "../../core/service/school-year.service";
import {Section} from "../../core/models/section";
import {ClasseService} from "../../core/service/classe.service";
import {Classe} from "../../core/models/classe";
import {ClasseCriteria} from "../../core/criteria/classe-criteria";
import {SearchParamsCriteria} from "../../core/models/search-params-criteria";

const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public config: any = {};
  isNavbarCollapsed = true;
  isOpenSidebar!: boolean;
  currentUser!: User;
  currentUserSchoolName!: string;
  currentUserSchoolType!: string;
  readonly formLabels = FormLabels;
  selectedLanguage: string = '';
  selectedSchoolId: number = 0;
  selectedSectionId: number | undefined = 0;
  selectedClassId: number | undefined = 0;
  selectedSchoolYearId: number | undefined = 0;
  schools: School[] = [];
  school!: School | undefined;
  sections: Section[] = [];
  classes: Classe[] = [];
  classe!: Classe;
  schoolYears: SchoolYear[] = [];
  isStaff: boolean = false;

  currentSchool!: School;
  currentSchoolYear!: SchoolYear;
  currentSchoolSection!: Section;
  currentClass!: Classe;
  enptyObject: Object = {}; //Objet vide a utiliser pour les reinitialisations de pages

  classeCriteria: ClasseCriteria = {
    schoolId: 0,
    sectionId: 0,
    schoolYearId: 0,
  }
  /**pour les recherches en fonction des criteres**/
  searchParams: SearchParamsCriteria = {
    sort: ['createAt,ASC'],
    range: [1, 20],
    filter: {
      ...this.classeCriteria
    },
    searchValue: '',
  };
  languages = [
    {value: 'fr', name: 'Français', flag: 'assets/images/flags/fr_flag.jpg'},
    {value: 'en', name: 'English', flag: 'assets/images/flags/uk_flag.jpg'}
  ];
  notifications: any[] = [
    {
      message: 'Please check your mail',
      time: '14 mins ago',
      icon: 'mail',
      color: 'nfc-green',
      status: 'msg-unread',
    },
    {
      message: 'New Patient Added..',
      time: '22 mins ago',
      icon: 'person_add',
      color: 'nfc-blue',
      status: 'msg-read',
    },
    {
      message: 'Your leave is approved!! ',
      time: '3 hours ago',
      icon: 'event_available',
      color: 'nfc-orange',
      status: 'msg-read',
    },
    {
      message: 'Lets break for lunch...',
      time: '5 hours ago',
      icon: 'lunch_dining',
      color: 'nfc-blue',
      status: 'msg-read',
    },
    {
      message: 'Patient report generated',
      time: '14 mins ago',
      icon: 'description',
      color: 'nfc-green',
      status: 'msg-read',
    },
    {
      message: 'Please check your mail',
      time: '22 mins ago',
      icon: 'mail',
      color: 'nfc-red',
      status: 'msg-read',
    },
    {
      message: 'Salary credited...',
      time: '3 hours ago',
      icon: 'paid',
      color: 'nfc-purple',
      status: 'msg-read',
    },
  ];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private notificationService: NotificationService,
    private translateService: AppTranslateService,
    private localStorageService: LocalStorageService,
    private schoolService: SchoolService,
    private classeService: ClasseService,
    private schoolYearService: SchoolYearService,
    private changeDetectorRef: ChangeDetectorRef
  ) {

  }

  getSchools = () => {
    this.schoolService.getSchools().pipe(
      tap(response => {
        this.schools = response;
      }),
      catchError((error: AppError) => {
        this.notificationService.handleError(error);
        return of([]);
      })
    )
      .subscribe(() => {
        this.loadSchoolSection();
      });
  }

  getClassroomBySearchParams = (searchParams: SearchParamsCriteria) => {
    this.classeService.getClassroomBySearchParams(searchParams).pipe(
      tap(response => {
        this.classes = response;
        console.log('this.this.classes qwerty:::', this.classes)
      }),
      catchError((error: AppError) => {
        this.notificationService.handleError(error);
        return of([]);
      })
    )
      .subscribe();
  }

  getSchoolYears = () => {
    this.schoolYearService.getSchoolYears().pipe(
      tap(response => {
        this.schoolYears = response;
      }),
      catchError((error: AppError) => {
        this.notificationService.handleError(error);
        return of([]);
      })
    )
      .subscribe();
  }

  /**
   * S'abonner aux changements de langue
   */
  changementDeLangue = (): void => {
    this.localStorageService.getStorageChanges().subscribe(() => {
      // Mettez à jour la langue avec la nouvelle valeur du local storage
      const newLanguage = this.localStorageService.getCurrentValue();
      this.translateService.setDefaultLanguage(newLanguage || 'en');
      this.selectedLanguage = this.translateService.getDefaultLanguage();
      // Forcer la détection des changements manuellement
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit() {
    this.config = this.configService.configData;
    if (this.authService.currentUserValue) {
      this.currentUser = this.authService.currentUserValue;
      this.currentUserSchoolName = this.currentUser?.data?.school?.libelle ?? '';
      this.currentUserSchoolType = this.currentUser?.data?.school?.schoolType ?? '';
    }
    this.changementDeLangue();
    this.isStaff = this.authService.checkUserGrade() === 'GRADE.STAFF';
    this.loadSchoolData();
  }

  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      // @ts-ignore
      this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem('menuOption')) {
      this.renderer.addClass(
        this.document.body,
        this.config.header.menuOption
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'menu_' + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        this.document.body,
        this.config.header.chooseLogoHeader
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'logo-' + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === 'close') {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      } else {
        this.renderer.removeClass(this.document.body, 'side-closed');
        this.renderer.removeClass(this.document.body, 'submenu-closed');
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      }
    }
  }

  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }

  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  public toggleRightSidebar(): void {
    this.rightSidebarService.sidebarState.subscribe((isRunning) => {
      this.isOpenSidebar = isRunning;
    });

    this.rightSidebarService.setRightSidebar(
      (this.isOpenSidebar = !this.isOpenSidebar)
    );
  }

  logout() {
    const accountId = this.currentUser.data?.account?.id;
    if (!accountId) {
      return;
    }
    this.authService.logout(accountId).pipe(
      tap(({message, success}: { message: string, success: boolean }) => {
        if (success) {
          this.router.navigate(['/authentication/signin']);
          this.notificationService.showSimpleSuccess(message);
        }
      }),
      tap(() => {
        console.log("valeur ici=>", this.currentUser);
      }),
      catchError((error) => {
        this.notificationService.handleError(error);
        return [];
      })
    ).subscribe();
  }

  /**
   * changement de langue en fonction de this.selectedLanguage
   */
  changeLanguage(): void {
    this.translateService.setDefaultLanguage(this.selectedLanguage);
    this.changeDetectorRef.detectChanges();
  }

  onSelectedSchool = (schoolId: number): void => {
    this.sections = [];
    this.selectedSectionId = 0;
    this.selectedClassId = 0;
    this.localStorageService.setCurrentSectionValue(this.enptyObject);
    this.localStorageService.setCurrentClassroomValue(this.enptyObject);
    this.selectedSchoolId = schoolId;
    const selectedSchool = this.schools.find(school => school.id === schoolId);
    this.school = selectedSchool;
    this.sections = selectedSchool?.sections ?? []; // Utilisation de l'opérateur de coalescence nulle (??) pour fournir une valeur par défaut
    this.classes = [];
    localStorage.setItem('currentSchool', JSON.stringify(selectedSchool));
    this.changeDetectorRef.detectChanges();
  };

  onSelectedSchoolSection = (sectionId: number): void => {
    this.classes = [];
    const selectedSection = this.sections.find(section => section.id === sectionId);
    if (selectedSection) {
      this.selectedSectionId = selectedSection.id;
      this.classeCriteria.sectionId = this.selectedSectionId;
      this.classeCriteria.schoolId = this.currentSchool.id;
      this.classeCriteria.schoolYearId = this.currentSchoolYear.id;
      this.localStorageService.setCurrentSectionValue(selectedSection);
      this.searchParams.filter = this.classeCriteria;
      this.getClassroomBySearchParams(this.searchParams);
    } else {
      this.sections = [];
      this.selectedSectionId = 0;
      this.localStorageService.setCurrentSectionValue(this.enptyObject);
    }
    this.selectedClassId = 0;
    this.localStorageService.setCurrentClassroomValue(this.enptyObject);
    this.changeDetectorRef.detectChanges();
  };

  onSelectedClass = (classId: number): void => {
    const selectedClass = this.classes.find(classe => classe.id === classId);
    if (selectedClass) {
      this.selectedClassId = selectedClass.id;
      this.localStorageService.setCurrentClassroomValue(selectedClass);
    } else {
      this.classes = [];
      this.selectedClassId = 0;
      this.localStorageService.setCurrentClassroomValue(this.enptyObject);
    }
    const isPresent = this.isClassPresent(classId);
    console.log(`Class ID ${classId} is present: ${isPresent}`);
    this.localStorageService.setCanAddOrUpdateNoteValue(isPresent);
    this.changeDetectorRef.detectChanges();
  };

  onSelectedSchoolYear = (schoolYearId: number) => {
    this.classes = [];
    this.selectedClassId = 0;
    this.selectedSchoolYearId = schoolYearId;
    const selectedSchoolYear = this.schoolYears.find(schoolYear => schoolYear.id === schoolYearId);
    if (selectedSchoolYear && this.currentSchoolSection != null && Object.keys(this.currentSchoolSection).length !== 0) {
      this.classeCriteria.schoolYearId = selectedSchoolYear.id;
      this.searchParams.filter = this.classeCriteria;
      this.getClassroomBySearchParams(this.searchParams);
    }
    localStorage.setItem('currentSchoolYear', JSON.stringify(selectedSchoolYear));
    this.changeDetectorRef.detectChanges();
  };

  loadLocalStorageData = (): void => {
    combineLatest([
      this.localStorageService.getCurrentSchoolChanges(),
      this.localStorageService.getCurrentSchoolYearChanges(),
      this.localStorageService.getCurrentSectionChanges(),
      this.localStorageService.getCurrentClassroomChanges()
    ]).subscribe(([selectedSchool, selectedSchoolYear, selectedSchoolSection, selectedClassroom]) => {
      // Mise à jour des valeurs
      this.currentSchool = selectedSchool ? JSON.parse(selectedSchool) : this.enptyObject;
      this.currentSchoolYear = selectedSchoolYear ? JSON.parse(selectedSchoolYear) : this.enptyObject;
      this.currentSchoolSection = selectedSchoolSection ? JSON.parse(selectedSchoolSection) : this.enptyObject;
      this.currentClass = selectedClassroom ? JSON.parse(selectedClassroom) : this.enptyObject;

      // Logique à exécuter après la mise à jour des valeurs
      this.selectedClassId = this.currentClass.id;
      this.classeCriteria.sectionId = this.currentSchoolSection.id;
      this.classeCriteria.schoolId = this.currentSchool.id;
      this.classeCriteria.schoolYearId = this.currentSchoolYear.id;
      this.searchParams.filter = this.classeCriteria;
      this.getClassroomBySearchParams(this.searchParams);
    });
  };


  private loadSchoolSection() {
    if (this.selectedSchoolId > 0) {
      const selectedSchool = this.schools.find(school => school.id === this.selectedSchoolId);
      this.sections = selectedSchool?.sections ?? [];
    }
    this.changeDetectorRef.detectChanges();
  }

  private loadSchoolData() {
    this.loadLocalStorageData();
    let currentSchoolData: any;

    if (this.currentSchool) {
      currentSchoolData = this.currentSchool;
    } else {
      currentSchoolData = this.isStaff ? this.enptyObject : this.authService.etablissement();
    }


    //initialiser l' annees scolaire avec celle selectionner
    timer(1000).pipe(
      tap(() => {
        if (this.currentSchoolYear) {
          this.selectedSchoolYearId = this.currentSchoolYear.id;
        }
      })
    ).subscribe();


    // Définir l'ID de l'école sélectionnée
    if (currentSchoolData != null && Object.keys(currentSchoolData).length !== 0) {
      this.selectedSchoolId = currentSchoolData.id;
    }

    // Définir l'ID de la section d'école sélectionnée
    if (this.currentSchoolSection != null && Object.keys(this.currentSchoolSection).length !== 0) {
      this.selectedSectionId = this.currentSchoolSection.id;
      this.classeCriteria.schoolId = this.selectedSectionId;
      this.classeCriteria.schoolYearId = this.currentSchoolYear.id;
      this.searchParams.filter = this.classeCriteria;
      this.getClassroomBySearchParams(this.searchParams);
    }


    // Stocker les données d'école et de section dans le local storage si nécessaire
    if (!this.currentSchool || this.isStaff) {
      this.localStorageService.setCurrentSchoolValue(this.currentSchool);
    }
    if (!this.currentSchoolSection) {
      this.localStorageService.setCurrentSectionValue(this.currentSchoolSection);
    }
    // Définir l'ID de la section d'école sélectionnée
    if (this.currentClass) {
      this.selectedClassId = this.currentClass.id;
    }

    // Récupérer les écoles si l'utilisateur est membre du personnel, sinon charger les sections de l'école sélectionnée
    if (this.isStaff) {
      this.getSchools();
    } else {
      this.sections = currentSchoolData.sections;
    }
    // Récupérer les années scolaires
    this.getSchoolYears();
    this.changeDetectorRef.detectChanges();
  }

  private isClassPresent(classId: number): boolean {
    // Récupérer les IDs des classes depuis le localStorage
    const classIdsString = localStorage.getItem('classIds');
    let retrievedClassIds: number[] = [];

    if (classIdsString) {
      retrievedClassIds = JSON.parse(classIdsString);
    }
    return true;
    // Vérifier si l'identifiant de la classe est présent dans la liste
    //return retrievedClassIds.includes(classId);
  }

}
