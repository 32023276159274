import {Injectable} from '@angular/core';
import {InConfiguration} from '../core/models/config.interface';
import {LoggerService} from "../core/service/logger.service";

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public configData!: InConfiguration;

  constructor(private logger: LoggerService) {
    this.setConfigData();
  }

  setConfigData() {
    this.configData = {
      layout: {
        rtl: false, // options:  true & false
        variant: 'light', // options:  light & dark
        theme_color: 'white', // options:  white, black, purple, blue, cyan, green, orange
        logo_bg_color: 'white', // options:  white, black, purple, blue, cyan, green, orange
        sidebar: {
          collapsed: false, // options:  true & false
          backgroundColor: 'light', // options:  light & dark
        },
      },
      header: {
        menuOption: localStorage.getItem('menuOption') !== null ? localStorage.getItem('menuOption') : "menuOption",
        chooseLogoHeader: localStorage.getItem('choose_logoheader') !== null ? localStorage.getItem('choose_logoheader') : "chooseLogoHeader",
      },
      sidebar: {
        chooseSkin: localStorage.getItem('choose_skin') !== null ? localStorage.getItem('choose_skin') : "chooseSkin",
        chooseSkinActive: localStorage.getItem('choose_skin_active') !== null ? localStorage.getItem('choose_skin_active') : "chooseSkinActive",
      }
    };
  }
}
