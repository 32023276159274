import { AuthService } from '../service/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface Message {
  error: {
    title: string;
    message: string;
  }
}
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const status = err.status;
        let errorObtenu = err.error;
        console.log("Erreur de connexion internefsdft", errorObtenu);
        console.log("Erreur de connexion status", status);
        switch (status) {
          case 400:
            console.error('Bad Request:', errorObtenu);
            break;
          case 401:
            console.error('Unauthorized:', errorObtenu);
            break;
          case 503:
            // Erreur de connexion, le serveur est indisponible
            const internetError: Message = {
              error: {
                title: "Erreur de connexion",
                message: "Impossible de se connecter au serveur. Veuillez vérifier votre connexion internet et réessayer."
              }
            };
            // auto logout if 401 response returned from api
            //this.authenticationService.logout();
            errorObtenu = internetError.error;
            break;

            case 0:
              console.error('Erreur CORS:', err);
              const erreurCORS: Message = {
                error: {
                  title: "Erreur inconnue",
                  message: "Une erreur inconnue s'est produite. Veuillez réessayer ultérieurement."
                }
              };
              errorObtenu = erreurCORS.error;
              break;

          default:
            break;
        }
        console.error('Bad Requestxsxsxsxs:', errorObtenu);
        return throwError(errorObtenu);
      })
    );

  }
}
