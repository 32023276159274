import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  MAX_TEXT_LENGTH = 100; // max length of text before truncating
  constructor() {
    console.log('Notification service');
  }

  truncateText(text: string, maxLength: number): string {
    if (text.length <= maxLength) {
      return text;
    }

    const truncatedText = text.slice(0, maxLength - 3); // -3 to account for the ellipsis
    return `${truncatedText}...`;
  }

  private getAlertBackground(type: any): string {
    switch (type) {
      case 'info':
        return '#6ab8f7';
      case 'success':
        return '#4caf61';
      case 'warning':
        return '#f7f06a';
      case 'error':
        return '#f77066';
      default:
        return '#ffffff';
    }
  }

  private showAlert(options: SweetAlertOptions) {
    const text = options.text;
    //const iconString = options.icon ? options.icon.toString() : '';
    //const background = iconString ? iconString : 'info';
    const textToDisplay = text ? this.truncateText(text, this.MAX_TEXT_LENGTH) : '';
    Swal.fire({
      ...options,
      text: textToDisplay,
      position: 'top-end',
      width: '400px',
      padding: '1rem',
      background: this.getAlertBackground('default'),//backround defini un back en fct de l'icone
      toast: true,
      timer: 5000, //Ajoute un délai de 2 secondes avant fermeture automatique de l'alerte en ms
      timerProgressBar: true, // Affiche une barre de progression
    });
  }

  showSuccess(message: any, title: any) {
    const options: SweetAlertOptions = {
      icon: 'success',
      title: title,
      text: message,
      showConfirmButton: false, // Masque le bouton de confirmation
    };
    this.showAlert(options);
  }

  showSimpleSuccess(message: any) {
    const options: SweetAlertOptions = {
      icon: 'success',
      text: message,
      showConfirmButton: false, // Masque le bouton de confirmation
    };
    this.showAlert(options);
  }

  showInfoWithTimeout(message: any, title: any) {
    const options: SweetAlertOptions = {
      icon: 'info',
      title: title,
      text: message,
      showConfirmButton: false, // Masque le bouton de confirmation
    };
    this.showAlert(options);
  }

  showSuccessWithTimeout(message: any, title: any) {
    const options: SweetAlertOptions = {
      icon: 'success',
      title: title,
      text: message,
      showConfirmButton: false, // Masque le bouton de confirmation
    };
    this.showAlert(options);
  }

  showErrorWithTimeout(message: any, title: any) {
    const options: SweetAlertOptions = {
      icon: 'error',
      title: title,
      text: message,
      showConfirmButton: false, // Masque le bouton de confirmation
    };
    this.showAlert(options);
  }

  showWarningWithTimeout(message: any, title: any) {
    const options: SweetAlertOptions = {
      icon: 'warning',
      title: title,
      text: message,
      showConfirmButton: false, // Masque le bouton de confirmation
    };
    this.showAlert(options);
  }

  showHTMLMessage(message: any, title: any) {
    const options: SweetAlertOptions = {
      icon: 'success',
      title: title,
      html: message,
      showConfirmButton: false, // Masque le bouton de confirmation
    };
    this.showAlert(options);
  }

  // Successful responses.
  handleResponse(res: any) {

  }

  // Error responses
  handleError(error: any) {
    this.showWarningWithTimeout(
      error.message,
      error.title
    );
  }

  showNotification(notificationType: 'success' | 'warning', message: string, title: string) {
    switch (notificationType) {
      case 'success':
        this.showSuccessWithTimeout(message, title);
        break;
      case 'warning':
        this.showWarningWithTimeout(message, title);
        break;
      default:
        break;
    }
  }

}
