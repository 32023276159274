import {NgModule } from '@angular/core';
import { TruncateWordPipe } from '../pipes/truncate-word.pipe';
import {TranslateModule} from "@ngx-translate/core";
import { DynamicTranslatePipe } from '../pipes/dynamic-translate.pipe';
import { WordingPipe } from '../pipes/wording.pipe';

// @ts-ignore
@NgModule({
  imports: [],
  exports: [
    TruncateWordPipe,
    TranslateModule,
    DynamicTranslatePipe,
    WordingPipe
  ],
  declarations: [
    TruncateWordPipe,
    DynamicTranslatePipe,
    WordingPipe
  ],
})
export class PipeModule {}
