import {Component, OnInit} from '@angular/core';
import {LoggerService} from "../../core/service/logger.service";

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.sass']
})
export class PageLoaderComponent implements OnInit {
  constructor(private logger: LoggerService) {
  }

  ngOnInit() {
  }
}
