export enum DateFormatEnum {
  /** Format : yyyy-MM-dd hh:mm:ss a (ex : 2025-01-27 12:09:09 am) */
  HARDWARE_DATE_FORMAT = "yyyy-MM-dd hh:mm:ss a",

  /** Format : yyyy-MM-dd hh:mm:ssa (ex : 2025-01-27 12:09:09am) */
  DATE_TIME_WITH_AM_PM = "yyyy-MM-dd hh:mm:ssa",

  /** Format : yyyy-MM-dd HH:mm:ss (ex : 2025-01-27 23:09:09) */
  DATE_TIME_24_HOUR = "yyyy-MM-dd HH:mm:ss",

  /** Format : yyyy-MM-dd (ex : 2025-01-27) */
  DATE_ONLY = "yyyy-MM-dd",

  /** Format : HH:mm:ss (ex : 23:09:09) */
  TIME_ONLY = "HH:mm:ss",

  /** Format : yyyy-MM-dd HH:mm:ss.SSS (ex : 2025-01-27 23:09:09.123) */
  DATE_TIME_WITH_MILLIS = "yyyy-MM-dd HH:mm:ss.SSS",

  YYYY_MM_DD = 'yyyy-MM-dd',
  DD_MM_YYYY = 'dd/MM/yyyy',
  MM_YYYY = 'MM-yyyy',
  MMM_YYYY = 'MMM YYYY',
  MMMM_YYYY = 'MMMM yyyy'
}
