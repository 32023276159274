import {PaymentStatus} from "../enums/payment-status";


export const PaymentStatusTranslations = {
  [PaymentStatus.PAID]: {
    en: 'Paid',
    fr: 'Payé',
  },
  [PaymentStatus.UNPAID]: {
    en: 'Unpaid',
    fr: 'Non payé',
  },
  [PaymentStatus.PENDING]: {
    en: 'Pending',
    fr: 'En attente',
  },
  [PaymentStatus.PARTIAL_PAID]: {
    en: 'Partial paid',
    fr: 'Partiellement payé',
  },
  // Ajoutez d'autres traductions pour Civilite au besoin
} as const;
