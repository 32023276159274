import {Injectable, OnInit} from "@angular/core";
import {CrudService} from "./crud.service";
import {AuthService} from "./auth.service";
import {User} from "../models/user";
import {UrlConfig} from "../url-config";
import {School} from "../models/school";
import {Section} from "../models/section";
import {SchoolYear} from "../models/school-year";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SchoolService implements OnInit {


  config: any;
  entity: any;
  payloads: any;
  currentUserSchoolId!: number;
  currentUserSchoolSectionId!: number;
  isTeacher!: boolean;
  user!: User;

  constructor(private crudService: CrudService, private authService: AuthService) {
    this.entity = UrlConfig.school;
  }

  ngOnInit(): void {
    this.currentUserSchoolId = this.authService.identifiantEcole();
    this.currentUserSchoolSectionId = this.authService.identifiantSection();
    this.isTeacher = this.authService.isTeacher();
  }

  getSchools = (): Observable<School[]> => this.crudService.getEntities(this.entity.list);
  getSchoolStats = (schoolYear: SchoolYear) => {
    const pathVariables = {schoolYearId: schoolYear.id};
    return this.crudService.getWithUrlParams(this.entity.list + '/{schoolYearId}', pathVariables).pipe();
  }

  updateSchool(data: School) {
    const {...payloads} = data;
    return this.crudService.updateEntity(this.entity.update, payloads).pipe();
  }

  addSchool(data: School) {
    const payloads = {...data};
    delete payloads.id; // Supprimer l'ID pour éviter les conflits lors de l'ajout
    return this.crudService.addEntity(this.entity.add, payloads).pipe();
  }

  deleteSchool(id: number) {
    return this.crudService.deleteEntity(this.entity.delete, id).pipe();
  }

  initializeCourse(school: School, section: Section) {
    const pathVariables = {schoolId: school.id, sectionId: section.id};
    return this.crudService.postWithUrlParams(this.entity.initcourses + '/{schoolId}/{sectionId}', pathVariables).pipe();
  }
}
